import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NexaConfigurationService } from '@nexa/configuration';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ConfigModalsValidation } from '../../../shared/dialogs/custom-modal/config/validation/config-modals-validation';
import { CustomModalComponent } from '../../../shared/dialogs/custom-modal/custom-modal.component';
import { AuthenticationDto } from '../../models/authentication.dto';

/**
 * Servicio encargado de controlar la autenticación
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * This service is available as an injectable class, with methods to perform HTTP requests.
   */
  private readonly httpClient: HttpClient;
  /**
   * Servicio encargado de obtener las configuraciones del entorno
   */
  private readonly configuration: NexaConfigurationService;
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _isAuthenticated: boolean;
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _isAffiliate: boolean;
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _isViable: boolean;
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _error: string;
  /**
   * Propiedad que contiene el access token
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _token: string;
  /**
   * Permite desplegar un modal
   */
  public dialog: MatDialog;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsValidation: ConfigModalsValidation;
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;
  /**
   * Gets the is authenticated
   */
  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }
  /**
   * Gets the is affiliate
   */
  public get isAffiliate(): boolean {
    return this._isAffiliate;
  }
  /**
   * Gets the is viable
   */
  public get isViable(): boolean {
    return this._isViable;
  }
  /**
   * Gets the error
   */
  public get error(): string {
    return this._error;
  }
  /**
   * Gets the access token
   */
  public get token(): string {
    return this._token;
  }

  /**
   * Crea una nueva instancia de AuthService
   */
  public constructor(
    httpClient: HttpClient,
    nexaConfigurationService: NexaConfigurationService,
    dialog: MatDialog,
    router: Router,
  ) {
    this.configuration = nexaConfigurationService;
    this.httpClient = httpClient;
    this._isAuthenticated = false;
    this._isAffiliate = false;
    this._isViable = false;
    this._error = '';
    this._token = '';
    this.dialog = dialog;
    this.configModalsValidation = new ConfigModalsValidation();
    this.router = router;
  }

  /**
   * Servicio encargado de iniciar el proceso de autenticación
   * @param token Token retornado por google
   * @param numberDocument Numero de documento del usuario
   * @param idTypeDocument Id del tipo de documento
   * @param typeDocument Tipo de documento
   */
  public authenticate(
    token: string,
    numberDocument: string,
    idTypeDocument: number,
    typeDocument: string,
  ): Observable<AuthenticationDto> {
    const json = {
      swagger: false,
      googleToken: token,
      numberDocument,
      idTypeDocument,
      typeDocument,
    };

    return this.configuration.getApi('auth').pipe(
      switchMap((uri) => {
        return this.httpClient
          .post<AuthenticationDto>(`${uri}/Control/Auth`, json, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'referer-route': this.router.url,
            }),
          })
          .pipe(
            tap((x) => {
              this._error = x.error;
              // eslint-disable-next-line
              if (x.isValid) {
                this._isAuthenticated = x.isValid;
                this._isAffiliate = x.isAffiliate;
                this._isViable = x.isViable;
                this._token = x.token;

              } else {

                this.dialog.open(CustomModalComponent, {
                  data: this.configModalsValidation.modalNoData(undefined),
                  width: '720px',
                  height: '420px',
                  panelClass: 'dialog-container',
                  autoFocus: false,
                  restoreFocus: false,
                  disableClose: true,
                });
                this.router.navigate(['/']);
              }
            }),
          );
      }),
    );
  }

  /**
   * Metodo encargado de construir los header para peticiones Http
   * @returns Retorna un objeto HttpHeaders con el token y content-type
   */
  public getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('referer-route', this.router.url);

    return this.appendAuthHeader(headers);
  }

  /**
   * Metodo encargado de asociar el token al header Authorization
   * @param headers Objeto al cual se le asociara el header Authorization con el token
   * @returns Retorna el objeto HttpHeaders con el encabezado Authorization
   */
  private appendAuthHeader(headers: HttpHeaders): HttpHeaders {
    if (this._token === '') {
      return headers;
    }
    const tokenValue = `Bearer ${this._token}`;

    return headers.set('Authorization', tokenValue);

  }
}
