import { CustomModalModel } from '../../models/custom-modal.model';

/**
 * Componentes modales para el modulo de asignación de citas
 */
export class ConfigModalsModifyCancel {
  /**
   * Configuración del modal
   */
  private readonly configDialog: CustomModalModel = {};
  /**
   * Metodo encargado de crear un componente modal al momento de cancelar una cita.
   */
  public modalCancel(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.titleModal = '¿Seguro que deseas cancelar tu cita?';
    this.configDialog.sizeTitleModalXl = '24px';
    this.configDialog.sizeTitleModalLg = '24px';
    this.configDialog.sizeTitleModalMd = '24px';
    this.configDialog.sizeTitleModalSm = '22px';
    this.configDialog.sizeTitleModalXs = '22px';
    this.configDialog.sizeTitleModalXss = '20px';
    this.configDialog.topTitleGapValue = '4%';
    this.configDialog.bottomTitleGapValue = '3%';
    this.configDialog.typeContentModal = 'template';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.topTextContentGapValue = '3%';
    this.configDialog.bottomTextContentGapValue = '8%';
    this.configDialog.contentImage = false;
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Cancelar';
    this.configDialog.topButtonsGapValue = '60px';
    this.configDialog.textNextButton = 'Cancelar Cita';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';
    this.configDialog.buttonNextHeightXl = '45px';
    this.configDialog.buttonNextHeightLg = '45px';
    this.configDialog.buttonNextHeightMd = '45px';
    this.configDialog.buttonNextHeightSm = '45px';
    this.configDialog.buttonNextHeightXs = '45px';
    this.configDialog.buttonNextHeightXss = '45px';
    this.configDialog.buttonBack = true;
    this.configDialog.textBackButton = 'No, volver';
    this.configDialog.buttonBackWidthXl = '158px';
    this.configDialog.buttonBackWidthLg = '158px';
    this.configDialog.buttonBackWidthMd = '158px';
    this.configDialog.buttonBackWidthSm = '158px';
    this.configDialog.buttonBackWidthXs = '158px';
    this.configDialog.buttonBackWidthXss = '158px';
    this.configDialog.buttonBackHeightXl = '45px';
    this.configDialog.buttonBackHeightLg = '45px';
    this.configDialog.buttonBackHeightMd = '45px';
    this.configDialog.buttonBackHeightSm = '45px';
    this.configDialog.buttonBackHeightXs = '45px';
    this.configDialog.buttonBackHeightXss = '45px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal al momento de modificar una cita.
   */
  public modalUpdate(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.titleModal = '¿Seguro que deseas modificar tu cita?';
    this.configDialog.sizeTitleModalXl = '24px';
    this.configDialog.sizeTitleModalLg = '24px';
    this.configDialog.sizeTitleModalMd = '24px';
    this.configDialog.sizeTitleModalSm = '22px';
    this.configDialog.sizeTitleModalXs = '22px';
    this.configDialog.sizeTitleModalXss = '20px';
    this.configDialog.topTitleGapValue = '4%';
    this.configDialog.bottomTitleGapValue = '3%';
    this.configDialog.bottomTextContentGapValue = '8%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = false;
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Modificar';
    this.configDialog.topButtonsGapValue = '10px';
    this.configDialog.textNextButton = 'Modificar Cita';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';
    this.configDialog.buttonNextHeightXl = '45px';
    this.configDialog.buttonNextHeightLg = '45px';
    this.configDialog.buttonNextHeightMd = '45px';
    this.configDialog.buttonNextHeightSm = '45px';
    this.configDialog.buttonNextHeightXs = '45px';
    this.configDialog.buttonNextHeightXss = '45px';
    this.configDialog.buttonBack = true;
    this.configDialog.textBackButton = 'No';
    this.configDialog.buttonBackWidthXl = '158px';
    this.configDialog.buttonBackWidthLg = '158px';
    this.configDialog.buttonBackWidthMd = '158px';
    this.configDialog.buttonBackWidthSm = '158px';
    this.configDialog.buttonBackWidthXs = '158px';
    this.configDialog.buttonBackWidthXss = '158px';
    this.configDialog.buttonBackHeightXl = '45px';
    this.configDialog.buttonBackHeightLg = '45px';
    this.configDialog.buttonBackHeightMd = '45px';
    this.configDialog.buttonBackHeightSm = '45px';
    this.configDialog.buttonBackHeightXs = '45px';
    this.configDialog.buttonBackHeightXss = '45px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal de cancelar cita exitoso.
   */
  public modalCancelApproved(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.titleModal = 'Cita cancelada con ÉXITO';
    this.configDialog.sizeTitleModalXl = '24px';
    this.configDialog.sizeTitleModalLg = '24px';
    this.configDialog.sizeTitleModalMd = '24px';
    this.configDialog.sizeTitleModalSm = '22px';
    this.configDialog.sizeTitleModalXs = '22px';
    this.configDialog.sizeTitleModalXss = '20px';
    this.configDialog.topTitleGapValue = '3%';
    this.configDialog.bottomTitleGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '8%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'top';
    this.configDialog.image = 'success.png';
    this.configDialog.widthImageXl = '95px';
    this.configDialog.widthImageLg = '95px';
    this.configDialog.widthImageMd = '95px';
    this.configDialog.widthImageSm = '95px';
    this.configDialog.widthImageXs = '95px';
    this.configDialog.widthImageXss = '95px';
    this.configDialog.heightImageXl = '95px';
    this.configDialog.heightImageLg = '95px';
    this.configDialog.heightImageMd = '95px';
    this.configDialog.heightImageSm = '95px';
    this.configDialog.heightImageXs = '95px';
    this.configDialog.heightImageXss = '95px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.topButtonsGapValue = '28px';
    this.configDialog.textNextButton = 'Finalizar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';
    this.configDialog.buttonNextHeightXl = '45px';
    this.configDialog.buttonNextHeightLg = '45px';
    this.configDialog.buttonNextHeightMd = '45px';
    this.configDialog.buttonNextHeightSm = '45px';
    this.configDialog.buttonNextHeightXs = '45px';
    this.configDialog.buttonNextHeightXss = '45px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal
   */
  public modalError204(service: string): CustomModalModel {
      this.configDialog.closeCornerButton = true;
      this.configDialog.closeCornerButtonPosAxisXl = ' 679px';
      this.configDialog.closeCornerButtonPosCrossXl = '-45px';
      this.configDialog.closeCornerButtonPosAxisLg = '679px';
      this.configDialog.closeCornerButtonPosCrossLg = '-45px';
      this.configDialog.closeCornerButtonPosAxisMd = '76%';
      this.configDialog.closeCornerButtonPosCrossMd = '-45px';
      this.configDialog.closeCornerButtonPosAxisSm = '74%';
      this.configDialog.closeCornerButtonPosCrossSm = '-45px';
      this.configDialog.closeCornerButtonPosAxisXs = '73%';
      this.configDialog.closeCornerButtonPosCrossXs = '-45px';
      this.configDialog.closeCornerButtonPosAxisXss = '72%';
      this.configDialog.closeCornerButtonPosCrossXss = '-45px';
      this.configDialog.textContentModal =  `<p>El servicio ${service} no esta disponible, por favor reintente<p>`;
      this.configDialog.sizeTextContentModalXl = '24px';
      this.configDialog.sizeTextContentModalLg = '24px';
      this.configDialog.sizeTextContentModalMd = '24px';
      this.configDialog.sizeTextContentModalSm = '22px';
      this.configDialog.sizeTextContentModalXs = '22px';
      this.configDialog.sizeTextContentModalXss = '20px';
      this.configDialog.topTextContentGapValue = '2%';
      this.configDialog.bottomTextContentGapValue = '1%';
      this.configDialog.typeContentModal = 'text';
      this.configDialog.typeTemplate = 'cancel';
      this.configDialog.contentImage = true;
      this.configDialog.locationImage = 'bottom';
      this.configDialog.image = 'warning.png';
      this.configDialog.widthImageXl = '91px';
      this.configDialog.widthImageLg = '91px';
      this.configDialog.widthImageMd = '91px';
      this.configDialog.widthImageSm = '91px';
      this.configDialog.widthImageXs = '91px';
      this.configDialog.heightImageXl = '91px';
      this.configDialog.heightImageLg = '91px';
      this.configDialog.heightImageMd = '91px';
      this.configDialog.heightImageSm = '91px';
      this.configDialog.heightImageXs = '91px';
      this.configDialog.topImageGapValue = '20px';
      this.configDialog.bottomImageGapValue = '50px';
      this.configDialog.buttonNext = true;
      this.configDialog.caseButtonNext = 'Default';
      this.configDialog.textNextButton = 'Volver';
      this.configDialog.buttonNextWidthXl = '158px';
      this.configDialog.buttonNextWidthLg = '158px';
      this.configDialog.buttonNextWidthMd = '158px';
      this.configDialog.buttonNextWidthSm = '158px';
      this.configDialog.buttonNextWidthXs = '158px';
      this.configDialog.buttonNextWidthXss = '158px';

      return this.configDialog;
    }

  /**
   * Metodo encargado de crear un componente modal personalizando titulo y descripción.
   */
   public modalAlertCustom(titulo: string, description: string): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.titleModal = titulo;
    this.configDialog.sizeTitleModalXl = '24px';
    this.configDialog.sizeTitleModalLg = '24px';
    this.configDialog.sizeTitleModalMd = '24px';
    this.configDialog.sizeTitleModalSm = '22px';
    this.configDialog.sizeTitleModalXs = '22px';
    this.configDialog.sizeTitleModalXss = '20px';
    this.configDialog.topTitleGapValue = '4%';
    this.configDialog.bottomTitleGapValue = '3%';
    this.configDialog.textContentModal = `<p> ${description} </p>`;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';

    return this.configDialog;
  }
}
