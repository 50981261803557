import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/**
 * Pipe encargado de sanetizar base64
 */
@Pipe({
  name: 'sanitizer',
})
export class SanitizerPipe implements PipeTransform {
  /**
   * Calling any of the `bypassSecurityTrust...` APIs disables Angular's built-in
   */
  private readonly sanitizer: DomSanitizer;

  public constructor(sanitizer: DomSanitizer) {
    this.sanitizer = sanitizer;
   }

  public transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

}
