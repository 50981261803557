import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para ordenar la información de un array de objetos
 */
@Pipe({
  name: 'sorted',
})
export class SortedPipe implements PipeTransform {
  /**
   * Metodo encargado de tranformar la información
   * @param value Arreglo que se va a ordenar
   * @param descending Argumento que indica si el ordenamiento es o no descendente
   */
  public transform(value: any[] | null, property: string = '', descending: boolean = false): any[] {
    if (!value || property === '') {
      return value as any[];
    }
    const element: string = property;

    const sortFn: (a: any, b: any) => any = (a, b) => {
      let result = 0;

      if (a[element] === undefined) {
        result = -1;
      } else if (b[element] === undefined) {
        result = 1;
      } else {
        result = a[element] > b[element] ? 1 : b[element] > a[element] ? -1 : 0;
      }

      return descending ? result * -1 : result;
    };

    value.sort(sortFn);

    return value;
  }
}
