import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth/authentication.service';

/**
 * Guard que se encarga de asegurar los modulos de trabajo
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanLoad {
  /**
   * Servicio que controla la autenticación
   */
  private readonly authService: AuthenticationService;
  /**
   * A service that provides navigation and URL manipulation capabilities.
   */
  private readonly router: Router;

  /**
   * Crea una nueva instancia de AuthenticatedGuard
   */
  public constructor(authService: AuthenticationService, router: Router) {
    this.authService = authService;
    this.router = router;
  }

  /**
   * Metodo que valida si el usuario se encuentra autenticado
   */
  public canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['/']);
    }

    return this.authService.isAuthenticated;
  }
}
