//Codigos de respuesta
export enum Codes {
  /**
   * Status Code 206 cuando fecha nacimiento es vacia
   */
  emptyBirthday = 206,
  /**
   * Status Code cuando el producto es solo cesantias
   */
  onlyProduct = 207
}
