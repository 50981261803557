import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { AgentInteraction } from './agent-interaction.interface';
import { initAgentInteraction } from './init-agent.const';

/**
 * AgentState
 */
export abstract class AgentInteractionState {
  /**
   * BehaviorSubject - State Base
   */
  private readonly agentInteractionState = new BehaviorSubject<AgentInteraction>(initAgentInteraction);

  /**
   * Observable Agent Interaction State
   */
  public agentInteractionState$ = this.agentInteractionState.asObservable();

  /**
   * Get the value state
   */
  public get value() {
    return _.cloneDeep(this.agentInteractionState.value);
  }

  /**
   * Metodo encargado de asignar el estado
   * @param state Nuevo estado a asignar
   */
  public next(state: AgentInteraction) {
    this.agentInteractionState.next(state);
  }

  /**
   * Asinga el estado de inicialización
   */
  public setInitialize() {
    const state = this.value;

    this.next(state);
  }
}
