import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NexaConfigurationService } from '@nexa/configuration';
import { EngineAvailabilityApiService } from '@prv/engine-availability';
import { GenderApiService } from '@prv/gender';
import { CountryApiService } from '@prv/georeference';
import { InteractionApiService } from '@prv/interaction';
import { ScheduleAssignedApiService } from '@prv/schedule-history';
import { DetailReasonStateSchedulingApiService, StateSchedulingApiService } from '@prv/scheduling-state';
import { TypeDocumentsApiService } from '@prv/type-document';
import { StructureTypologyApiService, TypeTypologyApiService } from '@prv/typology';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppModuleState } from './app.state';
import { ErrorComponent } from './components/error/error.component';
import { ValidationFacade } from './components/facade/validation.facade';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ValidationComponent } from './components/validation/validation.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ServerErrorInterceptor } from './core/interceptors/server-error/server-error.interceptor';
import { TokenInterceptorService } from './core/interceptors/token/token.interceptor';
import { ErrorService } from './core/services/error/error.service';
import { IpServiceService } from './core/services/ip-service/ip-service.service';
import { LoggingService } from './core/services/logging/logging.service';
import { ValidatorMessageService } from './core/services/validator-message/validator-message.service';
import { DialogModule } from './shared/dialogs/dialog.module';
import { CustomModalFacade } from './shared/dialogs/facade/custom-modal.facade';
import { SharedModule } from './shared/shared.module';

/**
 * Modulo principal de la aplicación
 */
@NgModule({
  declarations: [AppComponent, ErrorComponent, ForbiddenComponent, UnauthorizedComponent, WelcomeComponent, ValidationComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
    MatDialogModule,
    DialogModule,
    NgxSpinnerModule,
  ],
  providers: [
    AppModuleState,
    ValidationFacade,
    CustomModalFacade,
    AppModuleState,
    GenderApiService,
    CountryApiService,
    TypeDocumentsApiService,
    EngineAvailabilityApiService,
    StructureTypologyApiService,
    TypeTypologyApiService,
    EngineAvailabilityApiService,
    ScheduleAssignedApiService,
    StateSchedulingApiService,
    DetailReasonStateSchedulingApiService,
    InteractionApiService,
    ValidatorMessageService,
    LoggingService,
    ErrorService,
    ValidatorMessageService,
    IpServiceService,
    NexaConfigurationService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleCaptcha } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: NexaConfigurationService) => () => configService.loadConfigurations(environment).toPromise(),
      deps: [NexaConfigurationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
