import { Pipe, PipeTransform } from '@angular/core';

import { IKeyValue } from '../../models/key-value.model';

/**
 * Pipe encargado de procesar de convertir un objeto key-value a una lista procesable
 */
@Pipe({
  name: 'keyvalue',
})
export class KeyValuePipe implements PipeTransform {
  /**
   * Metodo encargado de transformar los valores
   * @param value Valor a procesar
   */
  public transform(value: any): IKeyValue[] {
    if (!value) {
      return [];
    }

    const keys = Object.keys(value);

    const data: IKeyValue[] = [];

    keys.forEach((key: string) =>
      data.push({
        key,
        value: value[key],
      }),
    );

    return data;
  }
}
