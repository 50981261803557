import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Error400 } from '../../models/error.400.model';

/**
 * Servicio de error para analizar mensajes y las trazas de pila.
 */
@Injectable()
export class ErrorService {
  /**
   * Metodo encargado de retornar el error del cliente
   * @param error Error del cliente a procesar
   */
  public getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  /**
   * Metodo encargado de obtener el mensaje del error del servidor
   * @param response Error del servidor a procesar
   */
  public getServerErrorMessage(response: HttpErrorResponse): string {
    return navigator.onLine ? response.message : 'No Internet Connection';
  }

  /**
   * Metodo encargado de obtener la traza del error del servidor
   * @param response Error del servidor a procesar
   */
  public getServerErrorStackTrace(response: HttpErrorResponse): string {
    return response.message;
  }

  /**
   * Metodo encargado de obtener el detalle del error con codigo 400
   * @param response Información del Error
   */
  public getServerError400(response: HttpErrorResponse): Error400 {
    const error400: Error400 = {
      ...response.error,
    };

    return error400;
  }
}
