import { CustomModalModel } from '../../models/custom-modal.model';

/**
 * Componentes modales para el modulo de asignación de citas
 */
export class ConfigModalsValidation {
  /**
   * Configuración del modal
   */
  private readonly configDialog: CustomModalModel = {};
  /**
   * Metodo encargado de crear un componente modal para tipologias .
   */
  public modalWithoutTypologies(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal =
      '<p>No hay tipologías para este usuario, si requiere más información contactarse a la línea de atención de porvenir<p>';
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Volver';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal para la busqueda por identificación.
   * @param message Mensaje personalizado @see string
   */
  public modalNoData(message: string | undefined): CustomModalModel {
    let messageModal = message;
    if (!messageModal) {
      messageModal = '<p>Por favor valide la identificación registrada e ingrese con los datos del afiliado. ' +
        'Si su documento esta correcto y usted se encuentra vinculado con Porvenir por favor contáctese con nuestra Línea de servicio al cliente <p>';
    }
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = messageModal;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Volver';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal de codigo de seguridad incorrecto.
   */
  public modalIncorrectDocument(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.titleModal =
      'Para este número de identificación no hay citas agendadas, verifique los datos ingresados para el filtro.';
    this.configDialog.sizeTitleModalXl = '24px';
    this.configDialog.sizeTitleModalLg = '24px';
    this.configDialog.sizeTitleModalMd = '24px';
    this.configDialog.sizeTitleModalSm = '22px';
    this.configDialog.sizeTitleModalXs = '22px';
    this.configDialog.sizeTitleModalXss = '20px';
    this.configDialog.topTitleGapValue = '6%';
    this.configDialog.bottomTitleGapValue = '3%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'top';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Volver';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal para la busqueda por identificación error en viabilidad.
   */
  public modalNoViability(): CustomModalModel {
    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal =
      '<p>Usted no presenta viabilidad para tomar esta cita, Por favor contáctese con la Linea de servicio al cliente.<p>';
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Salir';
    this.configDialog.textNextButton = 'Salir';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
  /**
   * Metodo encargado de crear un componente modal para la busqueda por identificación error en viabilidad.
   */
  public modalSendFailOtp(): CustomModalModel {
    this.configDialog.closeCornerButton = false;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = `<p class="text-parrafo center"><b>Señor afiliado:</b> Si deseas consultar o gestionar tu cita te invitamos a contactar a uno de nuestros asesores a través de la línea de servicio al cliente:</p></br>
    <p class="text-parrafo center"><b class="orange">Bogotá</b><b class="t1">601 744 76 78</b></p>
    <p class="text-parrafo center"><b class="orange">Cali</b><b class="t2">602 485 72 72</b></p>
    <p class="text-parrafo center"><b class="orange">Barranquilla</b><b class="t3">605 385 51 51</b></p>
    <p class="text-parrafo center"><b class="orange">Medellín</b><b class="t4">604 604 15 55</b></p>
    <p class="text-parrafo center"><b class="orange">Resto de Colombia</b><b class="t5">01 8000 510 800</b></p>
    <p class="text-parrafo center"><b class="orange">Resto de Colombia desde celular</b><b class="t6">#857</b></p>
    </br>
    `;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = false;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Volver';
    this.configDialog.textNextButton = 'Volver';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal para definir que el no cliente pertenece a otra AFP.
   * @param message Mensaje personalizado @see string
   */
  public modalOtherAFP(message: string | undefined): CustomModalModel {
    let messageModal = message;
    if (!messageModal) {
      messageModal = '<p>Actualmente no te encuentras vinculado con Porvenir al producto de Pensiones Obligatorias, verifica tu información. <p>';
    }

    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = messageModal;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = true;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = 'Default';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }

  /**
   * Metodo encargado de crear un componente modal para redireccionar a los no clientes
   * viables a la linea de atención al cliente
   * @param message Mensaje personalizado @see string
   */
  public modalLineAttentionDA(message: string | undefined): CustomModalModel {
    let messageModal = message;

    // Valida si el mensaje no es undefinied y settea uno por defecto
    if (!messageModal) {
      messageModal = `<p>Ahora podrás acceder a nuestra Doble Asesoría de inmediato a través de nuestra Línea de Servicio: <br/> Bogotá: 601 744 7678 <br/> Cali: 602 485 72 72 <br/> Barranquilla: 605 385 51 51 <br/> Medellín: 604 604 15 55 <br/> Resto de Colombia: 01 8000 510 800 <br/> Resto de Colombia desde celular #857 <br/> Solo necesitas contar con 30 minutos y estarás listo para recibirla. ¡Contáctanos ahora mismo!</p>`;
    }

    this.configDialog.closeCornerButton = true;
    this.configDialog.closeCornerButtonPosAxisXl = '679px';
    this.configDialog.closeCornerButtonPosCrossXl = '-45px';
    this.configDialog.closeCornerButtonPosAxisLg = '679px';
    this.configDialog.closeCornerButtonPosCrossLg = '-45px';
    this.configDialog.closeCornerButtonPosAxisMd = '76%';
    this.configDialog.closeCornerButtonPosCrossMd = '-45px';
    this.configDialog.closeCornerButtonPosAxisSm = '74%';
    this.configDialog.closeCornerButtonPosCrossSm = '-45px';
    this.configDialog.closeCornerButtonPosAxisXs = '73%';
    this.configDialog.closeCornerButtonPosCrossXs = '-45px';
    this.configDialog.closeCornerButtonPosAxisXss = '72%';
    this.configDialog.closeCornerButtonPosCrossXss = '-45px';
    this.configDialog.textContentModal = messageModal;
    this.configDialog.sizeTextContentModalXl = '24px';
    this.configDialog.sizeTextContentModalLg = '24px';
    this.configDialog.sizeTextContentModalMd = '24px';
    this.configDialog.sizeTextContentModalSm = '22px';
    this.configDialog.sizeTextContentModalXs = '22px';
    this.configDialog.sizeTextContentModalXss = '20px';
    this.configDialog.topTextContentGapValue = '2%';
    this.configDialog.bottomTextContentGapValue = '1%';
    this.configDialog.typeContentModal = 'text';
    this.configDialog.typeTemplate = 'cancel';
    this.configDialog.contentImage = false;
    this.configDialog.locationImage = 'bottom';
    this.configDialog.image = 'warning.png';
    this.configDialog.widthImageXl = '91px';
    this.configDialog.widthImageLg = '91px';
    this.configDialog.widthImageMd = '91px';
    this.configDialog.widthImageSm = '91px';
    this.configDialog.widthImageXs = '91px';
    this.configDialog.widthImageXss = '91px';
    this.configDialog.heightImageXl = '91px';
    this.configDialog.heightImageLg = '91px';
    this.configDialog.heightImageMd = '91px';
    this.configDialog.heightImageSm = '91px';
    this.configDialog.heightImageXs = '91px';
    this.configDialog.heightImageXss = '91px';
    this.configDialog.topImageGapValue = '20px';
    this.configDialog.bottomImageGapValue = '50px';
    this.configDialog.buttonNext = true;
    this.configDialog.caseButtonNext = !messageModal ? 'Default' : 'Micrositio';
    this.configDialog.textNextButton = 'Aceptar';
    this.configDialog.buttonNextWidthXl = '158px';
    this.configDialog.buttonNextWidthLg = '158px';
    this.configDialog.buttonNextWidthMd = '158px';
    this.configDialog.buttonNextWidthSm = '158px';
    this.configDialog.buttonNextWidthXs = '158px';
    this.configDialog.buttonNextWidthXss = '158px';

    return this.configDialog;
  }
}
