import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

/**
 * Componente principal de la aplicación que estructura el header, footer y sección de trabajo
 */
@Component({
  selector: 'prv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;
  /**
   * Activo footer
   */
  public showHead = false;
  /**
   * initial AppComponent
   * @param router rutas
   */
  public constructor(router: Router) {
    this.router = router;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        event.url === '/assignment-module/printTemplate/PrintSchedule' ||
        event.url === '/search-module/printTemplate/PrintSchedule'
          ? (this.showHead = false)
          : (this.showHead = true);
      }
    });
  }

  /**
   * Metodo que se ejecuta cuando de detecta que se recargara la pagina
   * @param event Información del Evento
   */
  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: Event) {
    event.preventDefault();
  }
}
