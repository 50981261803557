import { Injectable } from '@angular/core';

/**
 * Servicio para registrar los logs.
 */
@Injectable()
export class LoggingService {
  /**
   * Level Debug
   * @param message Mensaje a escribir
   * @param args Información a realacionar
   */
  public logDebug(message: string, args?: any) {
    console.log(`LoggingService: ${message}`, args);
  }

  /**
   * Level Warning
   * @param message Mensaje a escribir
   * @param args Información a realacionar
   */
  public logWarning(message: string, args?: any) {
    console.warn(`LoggingService: ${message}`, args);
  }

  /**
   * Metodo encargado de almacenar el error para su posterior procesamiento
   * @param message Mensaje de error
   * @param stack Traza del error
   */
  public logError(message: string, stack: string) {
    /* Send errors to server here */
    console.log(`LoggingService: ${message}`, stack);
  }
}
