import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Componenten encargado de administrar el header de la pagina
 */
@Component({
  selector: 'prv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /**
   * Estado en el Tipo de Header
   */
  @Input() public typeHeader: string | undefined;

  /**
   * Estado en el Tipo de Header
   */
  public fullName: string | undefined;

  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;

  /**
   *  almacena la ip address
   */
  public ipAddress: string | undefined;

  /**
   * Es la varibles que recibira el header para que sea dinamico
   */
  public ngOnInit(): void {
    this.ipAddress = localStorage.getItem('ip')?.replace(/['"]+/g, '') || ' ';
    this.fullName = localStorage.getItem('clientName')?.trim().replace(/['"]+/g, '') || ' ';
  }
  /**
   * Crea una nueva instancia de VerifyDataComponent
   */
  public constructor(router: Router) {
    this.router = router;
  }
  /**
   * Refrescar pagina web
   */
  public refresh(): void {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
