import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IsEmptyPipe } from './pipes/isEmpty/is-empty.pipe';
import { KeyValuePipe } from './pipes/keyValue/key-value.pipe';
import { SortedPipe } from './pipes/sorted-pipe/sorted.pipe';
import { StatePipe } from './pipes/state/state.pipe';
import { SanitizerPipe } from './pipes/sanitizer/sanitizer.pipe';

/**
 * Modulo de componentes compartidos
 */
@NgModule({
  declarations: [IsEmptyPipe, KeyValuePipe, StatePipe, SortedPipe, HeaderComponent, SanitizerPipe, FooterComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule],
  exports: [IsEmptyPipe, KeyValuePipe, StatePipe, SortedPipe, HeaderComponent, FooterComponent,SanitizerPipe],
})
export class SharedModule {}
