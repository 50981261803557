import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe encargado de validar si un objeto se encuentra vacio
 */
@Pipe({
  name: 'isEmpty',
})
export class IsEmptyPipe implements PipeTransform {
  /**
   * Metodo encargado de validar si el array or object is empty
   * @param value Object or Array to validate
   * @returns True if value is empty.
   */
  public transform(value: {} | [] | null): boolean {
    if (value === null) {
      return true;
    }

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return Object.keys(value).length === 0;
  }
}
