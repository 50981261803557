<router-outlet></router-outlet>
<router-outlet name="print"></router-outlet>
<prv-footer *ngIf="showHead"></prv-footer>
<ngx-spinner
  bdOpacity="0.9"
  bdColor="rgba(210,209,209,0.35)"
  size="medium"
  color="#555555"
  type="timer"
  [fullScreen]="true"
>
  <p style="color: rgb(85, 85, 85, 1)">Cargando...</p>
</ngx-spinner>
