import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Error500 } from '../../models/error.500.model';
import { ErrorService } from '../../services/error/error.service';

/**
 * Interceptor encargado de reintentar la petición y de validar los errores específicos
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  /**
   * A service that provides navigation and URL manipulation capabilities.
   */
  private readonly router: Router;
  /**
   * Servicio para procesar el error
   */
  private readonly errorService: ErrorService;

  /**
   * Crea una nueva instancia de ServerErrorInterceptor
   * @param router A service that provides navigation and URL manipulation capabilities.
   * @param errorService Servicio para procesar el error
   */
  public constructor(router: Router, errorService: ErrorService) {
    this.router = router;
    this.errorService = errorService;
  }

  /**
   * Identifies and handles a given HTTP request.
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns An observable of the event stream.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(2),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 400) {
          const error400 = this.errorService.getServerError400(errorResponse);

          const filedsMap = new Map<string, any>(Object.entries(error400.errors));

          let errors: string[] = [];

          Object.keys(error400.errors).forEach((x: string) => (errors = [...errors, ...(filedsMap.get(x) || [])]));
        }

        if (errorResponse.status === 401) {
          this.router.navigate(['/unauthorized']);
        }

        if (errorResponse.status === 500) {
          const error: Error500 = errorResponse.error;

          console.log('Interceptor', error);
        }

        return throwError(errorResponse);
      }),
    );
  }
}
