import {
  Cancelation,
  Channel,
  CityContact,
  Contact,
  Field,
  Form,
  Gestion,
  MetadataTypify,
  Office,
  PayloadScheduling,
  Preparation,
  StateScheduling,
  StructureForm,
  TypeContact,
  TypeDocument,
  Typify,
  User,
} from '@prv/schedule-history';

import { AgentInteraction } from './agent-interaction.interface';
import { HistoryInteraction } from './history/history-interaction.interface';

/**
 * Estado inicial de la oficina
 */
export const office: Office = {
  id: 0,
  name: '',
};

/**
 * Estado inicial del usuario
 */
export const user: User = {
  fullName: '',
};

/**
 * Estado inicial de canal
 */
export const channel: Channel = {
  id: 0,
  name: '',
};

/**
 * Estado inicial de estados de agendamiento
 */
export const stateScheduling: StateScheduling = {
  id: 0,
  name: '',
};

/**
 * Estado inicial del tipo de documento
 */
export const typeDocument: TypeDocument = {
  id: 0,
  name: '',
  mark: '',
};

/**
 * Estado inicial del tipo de contacto
 */
export const typeContact: TypeContact = {
  id: 0,
  name: '',
};

/**
 * Estado inicial de ciudad
 */
export const city: CityContact = {
  id: 0,
  name: '',
  idDepartment: 0,
  department: '',
};

/**
 * Estado inicial del contacto
 */
export const contact: Contact = {
  idTypeDocument: 0,
  numberDocument: '',
  fullName: '',
  cellPhones: [],
  localPhones: [],
  emails: [],
  habeasData: true,
};

/**
 * Estado inicial de la cancelación
 */
export const preparation: Preparation = {
  contactGestion: '',
  observations: '',
};

/**
 * Estado inicial de la cancelación
 */
export const cancelation: Cancelation = {
  nameDetailReasonByPorvenir: '',
  nameDetailReasonByAfiliado: '',
};

/**
 * Estado inicial de la gestion
 */
export const gestion: Gestion = {
  attendendAppointment: '',
  efectiveAppointment: '',
  typeThirdAttorney: '',
  causalAttendend: '',
  causalEfective: '',
  observations: '',
};

/**
 * Estado inicial de campos de formulario
 */
export const field: Field = {
  type: '',
  key: '',
  value: '',
};

/**
 * Estado inicial del formulario dinámico
 */
export const form: Form = {
  name: '',
  data: [field],
};

/**
 * Estado inicial de la estructura de tipificación
 */
export const structureForm: StructureForm = {
  id: 0,
  isValid: false,
  isSubscribed: false,
  name: '',
  type: '',
  data: [form],
};

/**
 * Estado inicial de la metadata de tipificación
 */
export const metadataTypify: MetadataTypify = {
  index: 0,
  form: structureForm,
};

/**
 * Estado inicial del tipificacion
 */
export const typify: Typify = {
  id: '',
  idHomolog: 0,
  guid: '',
  name: '',
  maxTimeMinutes: 0,
  colorHexadecimal: '',
};

/**
 * InteractionScheduling
 */
export const payloadScheduling: PayloadScheduling = {
  idChannel: 0,
  dateCall: '',
  dateScheduling: '',
  timeScheduling: '',
  typify,
  contact,
  assitent: contact,
  stateScheduling,
  channel,
  office,
  userCreator: user,
  userGestorAssigned: user,
};

/**
 * HistoryInteraction
 */
export const historyInteraction: HistoryInteraction = {
  count: 0,
  limit: 0,
  offset: 0,
  rows: [],
};

/**
 * Estado Inicial
 */
export const initAgentInteraction: AgentInteraction = {
  historyInteraction,
  isCompleted: false,
  isInitialize: false,
  payloadScheduling,
};
