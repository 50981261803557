import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { InteractionApiService } from '@prv/interaction';

import { AppRoutingModule } from '../../app-routing.module';
import { ModifyCancelFacade } from '../../modules/modify-cancel/facades/modify-cancel.facade';
import { EncryptService } from '../services/encrypt/encrypt.service';

import { CustomModalComponent } from './custom-modal/custom-modal.component';

/**
 * Modulo para los modals
 */
@NgModule({
  declarations: [CustomModalComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
  ],
  providers: [ModifyCancelFacade, InteractionApiService, EncryptService],
})
export class DialogModule {}
