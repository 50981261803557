import { Injectable } from '@angular/core';

/**
 * servicio encargado de proveer la ip address
 */
@Injectable({
  providedIn: 'root',
})
export class IpServiceService {

  public getIPAddress() {

    fetch('https://ipv4.jsonip.com')
      .then((response) => response.json())
      .then((info) => {
        localStorage.setItem('ip', info.ip);
      });
  }
}
