import { Injectable } from '@angular/core';
import { AvailabilityDto } from '@prv/engine-availability';
import { CityDto, CountryDto, DepartmentDto, NodeDto } from '@prv/georeference';
import { ChannelDto } from '@prv/georeference/lib/models/channel.dto';
import { PositionDto, PositionTypeTypologyDto } from '@prv/position';
import { Contact, DoubleAdvisory, PayloadScheduling, ScheduleDto, Typify } from '@prv/schedule-history';
import { DetailReasonStateSchedulingDto, StateSchedulingDto } from '@prv/scheduling-state';
import { TypeDocumentDto } from '@prv/type-document';
import { PayloadTypologySettingsDto, StructureTypologyDto, TypesAttendeeDto, TypeTypologyDto } from '@prv/typology';
import { ServantDto } from '@prv/user';
import { AccountDataDto, BasicDataAffiliateDto, TypologyDto } from '@prv/ws-basic-data';
import { ObjectResponse } from '@prv/ws-schedule-claim/lib/models/object-response/object-response.dto';
import { ResponseDto } from '@prv/ws-viability';
import { DataSegmenterDto } from '@prv/ws-segmenter';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import { DataScheduleCurrent } from './shared/models/data-schedule-current.model';
import { AgentInteraction } from './state/agent-interaction.interface';
import { AgentInteractionState } from './state/agent-interaction.state';
import { initAgentInteraction } from './state/init-agent.const';

/**
 * Estado de AppModule
 */
@Injectable()
export class AppModuleState extends AgentInteractionState {
  /**
   * Emite el listado de las disponibilidades calculadas en el motor de agendamiento
   */
  private readonly bsAvailabilities = new BehaviorSubject<AvailabilityDto[] | undefined>([]);
  /**
   * Emite los datos del afiliado obtenidos del WS Basic Data
   */
  private readonly bsBasicDataAffiliate = new BehaviorSubject<BasicDataAffiliateDto>({});
  /**
   * Emite lel listado de los paises
   */
  private readonly bsCountries = new BehaviorSubject<CountryDto[]>([]);
  /**
   * Emite el listado de las ciudades
   */
  private readonly bsCities = new BehaviorSubject<CityDto[]>([]);
  /**
   * Emite el listado de las ciudades
   */
  private readonly bsCitiesAll = new BehaviorSubject<CityDto[]>([]);
  /**
   * Emite el listado de las ciudades
   */
  private readonly bsCitiesOne = new BehaviorSubject<CityDto[]>([]);
  /**
   * Emite la estructura key/Value de las ciudades
   */
  private readonly bsCitiesKey = new BehaviorSubject<{ [id: number]: string }>({});
  /**
   * Emite el listado de detalles de motivos de estados de agendamiento
   */
  private readonly bsDetailReasonStateScheduling = new BehaviorSubject<DetailReasonStateSchedulingDto[]>([]);
  /**
   * Emite el listado de losa datos del detalle de la estructura de tipologia
   */
  private readonly bsDetailStructureTypologies = new BehaviorSubject<PayloadTypologySettingsDto[]>([]);
  /**
   * Emite la información del detalle de la tipologia seleccionada
   */
  private readonly bsDetailTypologySelected = new BehaviorSubject<PayloadTypologySettingsDto>({});
  /**
   * Emite los valores de los datos de la cuenta
   */
  private readonly bsDataAccount = new BehaviorSubject<AccountDataDto>({});
  /**
   * Emite el listado de los departamentos
   */
  private readonly bsDepartments = new BehaviorSubject<DepartmentDto[]>([]);
  /**
   * Emite el listado de todos los departamentos
   */
  private readonly bsDepartmentsAll = new BehaviorSubject<DepartmentDto[]>([]);
  /**
   * Emite la estructura key/value de los departamentos
   */
  private readonly bsDepartmentsKey = new BehaviorSubject<{ [id: number]: string }>({});
  /**
   * Emite la estructura key/value de los generos
   */
  private readonly bsGendersKey = new BehaviorSubject<{ [id: number]: string }>({});
  /**
   * Emite la informacion de la bandera de habeas data
   */
  private readonly bsHabeasData = new BehaviorSubject<boolean>(false);
  /**
   * Emite la informacion de la bandera de viabilidad
   */
  private readonly bsIsViable = new BehaviorSubject<boolean>(false);
  /**
   * Emite la informacion de la bandera de afiliación
   */
  private readonly bsIsAffiliate = new BehaviorSubject<boolean>(false);
  /**
   * Emite la informacion de la bandera de Doble Asesoria
   */
  private readonly bsIndicadorDobleAsesoria = new BehaviorSubject<boolean>(false);
  /**
   * Emite los valores del indicador de agendar cita
   */
  private readonly bsIndicator = new BehaviorSubject<string>('');
  /**
   * Emite el dato del id de solicitud de agendar cita
   */
  private readonly bsIdSolicitud = new BehaviorSubject<number>(0);
  /**
   * Emite la lista de ids de canales
   */
  private readonly bsIdChannels = new BehaviorSubject<number[]>([]);
  /**
   * Emite el listado de los nodos (oficinas)
   */
  private readonly bsNodes = new BehaviorSubject<NodeDto[]>([]);
  /**
   * Emite el listado de los nodos (oficinas)
   */
  private readonly bsNodesAll = new BehaviorSubject<NodeDto[]>([]);
  /**
   * Emite los datos del nodo (oficina)
   */
  private readonly bsOfficeId = new BehaviorSubject<NodeDto>({});
  /**
   * Emite la información para el agendamiento
   */
  private readonly bsPayloadSchedulingModify = new BehaviorSubject<PayloadScheduling>({ contact: {} });
  /**
   * Emite el listado de las tipologias para el filtro (control select)
   */
  private readonly bsTypologiesFilter = new BehaviorSubject<PayloadTypologySettingsDto[]>([]);
  /**
   * Emite los valores mas recientes de la tipologia seleccionada
   */
  private readonly bsTypologySelected = new BehaviorSubject<Typify>({});
  /**
   * Emite el listado de los cargos asociados a ciertos tipos de tipologia
   */
  private readonly bsPositions = new BehaviorSubject<PositionTypeTypologyDto[]>([]);
  /**
   * Emite el listado de los cargos asociados a ciertos tipos de tipologia
   */
  private readonly bsPositionTypeTypologies = new BehaviorSubject<PositionTypeTypologyDto[]>([]);
  /**
   * Emite los valores mas recientes del motivo de la tipologia seleccionada
   */
  private readonly bsReasonTypologySelected = new BehaviorSubject<DoubleAdvisory>({});
  /**
   * Emite el listado de las ciudades
   */
  private readonly bsResidentialCities = new BehaviorSubject<CityDto[]>([]);
  /**
   * Emite los datos del funcionario
   */
  private readonly bsServant = new BehaviorSubject<ServantDto>({});
  /**
   * Emite el listado de las agendas
   */
  private readonly bsSchedule = new BehaviorSubject<ScheduleDto[]>([]);
  /**
   * Emite los valores de las agendas del asistente
   */
  private readonly bsScheduleAssistant = new BehaviorSubject<ScheduleDto[]>([]);
  /**
   * Emite los datos del agendamiento por cierto id
   */
  private readonly bsScheduleId = new BehaviorSubject<ScheduleDto>({});
  /**
   * Emite el listado de agendamientos por cierta tipologia
   */
  private readonly bsScheduleTypology = new BehaviorSubject<ScheduleDto[]>([]);
  /**
   * Emite el listado de estados de de agenda por id state
   */
  private readonly bsScheduleState = new BehaviorSubject<StateSchedulingDto[]>([]);
  /**
   * Emite los valores mas recientes del stream de agenda lista
   */
  private readonly bsScheduleList = new BehaviorSubject<DataScheduleCurrent[]>([]);
  /**
   * Emite el valor mas reciente de la estructura de tipologia
   */
  private readonly bsStructureTypology = new BehaviorSubject<StructureTypologyDto>({});
  /**
   * Total Agendas informacion adicional por ws
   */
  private readonly bsScheduleClaim = new BehaviorSubject<ObjectResponse>({});
  /**
   * Información del servicio semanas cotizadas
   */
  private readonly bsSegmenter = new BehaviorSubject<DataSegmenterDto>({});
  /**
   * Emite el listado de los tipos de documentos
   */
  private readonly bsTypeDocuments = new BehaviorSubject<TypeDocumentDto[]>([]);
  /**
   * Emite el listado de los tipos de tipologia
   */
  private readonly bsTypeTypologies = new BehaviorSubject<TypeTypologyDto[]>([]);
  /**
   * Emite los valores mas recientes del stream de tipos de asistente de la tipologia seleccionada
   */
  private readonly bsTypesAttendeeTypologySelected = new BehaviorSubject<TypesAttendeeDto[]>([]);
  /**
   * Emite el valor mas reciente de la tipos de tipologia
   */
  private readonly bsTypologiesFromCRM = new BehaviorSubject<TypologyDto[]>([]);
  /**
   * Total disponibilidades mañana
   */
  private readonly bsTotalTomorrow = new BehaviorSubject<AvailabilityDto[] | undefined>([]);
  /**
   * Total disponibilidades para esta semana
   */
  private readonly bsTotalItWeek = new BehaviorSubject<AvailabilityDto[] | undefined>([]);
  /**
   * Total disponibilidades para este año
   */
  private readonly bsTotalItYear = new BehaviorSubject<AvailabilityDto[] | undefined>([]);
  /**
   * Total disponibilidades para este mes
   */
  private readonly bsTotalItMonth = new BehaviorSubject<AvailabilityDto[] | undefined>([]);
  /**
   * Informacion de la viabilidad
   */
  private readonly bsViability = new BehaviorSubject<ResponseDto>({});

  /**
   * Observable Agent Interaction State
   */
  public payloadSchedulingModify$ = this.bsPayloadSchedulingModify.asObservable();
  /**
   * Observable con la información de las disponibilidades calculadas en el motor de agendamiento
   */
  public readonly availabilities$ = this.bsAvailabilities.asObservable();
  /**
   * Observable con información de los tipos de documento
   */
  public readonly basicDataAffiliate$ = this.bsBasicDataAffiliate.asObservable();
  /**
   * Observable con información de las ciudades
   */
  public readonly cities$ = this.bsCities.asObservable();
  /**
   * Observable con información de las ciudades
   */
  public readonly citiesAll$ = this.bsCitiesAll.asObservable();
  /**
   * Observable con información de las ciudades
   */
  public readonly citiesOne$ = this.bsCitiesOne.asObservable();
  /**
   * Observable con información de las ciudades
   */
  public readonly citiesKey$ = this.bsCitiesKey.asObservable();
  /**
   * Observable con información de los paises
   */
  public readonly countries$ = this.bsCountries.asObservable();
  /**
   * Observable con la información de la cuenta
   */
  public readonly dataAccount$ = this.bsDataAccount.asObservable();
  /**
   * Observable con información de los departamentos
   */
  public readonly departments$ = this.bsDepartments.asObservable();
  /**
   * Observable con información de los departamentos
   */
  public readonly departmentsAll$ = this.bsDepartmentsAll.asObservable();
  /**
   * Observable con información de los departamentos
   */
  public readonly departmentsKey$ = this.bsDepartmentsKey.asObservable();
  /**
   * Observable con información de los Estados
   */
  public readonly detailReasonStateScheduling$ = this.bsDetailReasonStateScheduling.asObservable();
  /**
   * Observable con información del detalle de la estructura de tipologia
   */
  public readonly detailStructureTypologies$ = this.bsDetailStructureTypologies.asObservable();
  /**
   * Observable con información del detalle de la tipologia seleccionada
   */
  public readonly detailTypologySelected$ = this.bsDetailTypologySelected.asObservable();
  /**
   * Observable con información de los generos
   */
  public readonly gendersKey$ = this.bsGendersKey.asObservable();
  /**
   * Observable con información del habeas data
   */
  public readonly habeasData$ = this.bsHabeasData.asObservable();
  /**
   * Observable con información de los ids de los canales
   */
  public readonly idChannels$ = this.bsIdChannels.asObservable();
  /**
   * Observable con la información de viabilidad
   */
  public readonly isViable$ = this.bsIsViable.asObservable();
  /**
   * Observable con la información de viabilidad
   */
  public readonly isAffiliate$ = this.bsIsAffiliate.asObservable();
  /**
   * Observable con la información del indicador de Doble Asesoria
   */
  public readonly indicadorDobleAsesoria$ = this.bsIndicadorDobleAsesoria.asObservable();
  /**
   * Observable con información de las oficinas
   */
  public readonly nodes$ = this.bsNodes.asObservable();
  /**
   * Observable con información de las oficinas
   */
  public readonly nodesAll$ = this.bsNodesAll.asObservable();
  /**
   * Observable con la información de los cargos que pueden atender cierta tipologia
   */
  public readonly positions$ = this.bsPositions.asObservable();
  /**
   * Observable con la información de cargos por tipos de tipologias
   */
  public readonly positionTypeTypologies$ = this.bsPositionTypeTypologies.asObservable();
  /**
   * Observable con información de las ciudades
   */
  public readonly residentialCities$ = this.bsResidentialCities.asObservable();
  /**
   * Observable con información de Agendamiento
   */
  public readonly schedule$ = this.bsSchedule.asObservable();
  /**
   * Observable con información de Calendario
   */
  public readonly scheduleAssistant$ = this.bsScheduleAssistant.asObservable();
  /**
   * Observable con información de agendamiento por id
   */
  public readonly scheduleId$ = this.bsScheduleId.asObservable();
  /**
   * Observable con información del agendamiento
   */
  public readonly scheduleState$ = this.bsScheduleState.asObservable();
  /**
   * Observable con información de Calendario por tipologia
   */
  public readonly scheduleTypology$ = this.bsScheduleTypology.asObservable();
  /**
   * Observable con la información de agenda lista
   */
  public readonly scheduleList$ = this.bsScheduleList.asObservable();
  /**
   * Observable con la información adicional de las agendas por ws
   */
  public readonly scheduleClaim$ = this.bsScheduleClaim.asObservable();
  /**
   * Observable con la información del funcionario
   */
  public readonly servant$ = this.bsServant.asObservable();
  /**
   * Observable con información de la estructura de tipologia
   */
  public readonly structureTypology$ = this.bsStructureTypology.asObservable();
  /**
   * Observable con información de la estructura de tipologia
   */
  public readonly segmenter$ = this.bsSegmenter.asObservable();
  /**
   * Observable con información del listado de tipologias para filtros (control select)
   */
  public readonly typologiesFilter$ = this.bsTypologiesFilter.asObservable();
  /**
   * Observable con información de la tipologia seleccionada
   */
  public readonly typologySelected$ = this.bsTypologySelected.asObservable();
  /**
   * Observable con información de la tipos de tipologia
   */
  public readonly typeTypologies$ = this.bsTypeTypologies.asObservable();
  /**
   * Observable con información de los tipos de asistente de la tipologia seleccionada
   */
  public readonly typesAttendeeTypologySelected$ = this.bsTypesAttendeeTypologySelected.asObservable();
  /**
   * Observable con información de la tipos de tipologia
   */
  public readonly typologiesFromCRM$ = this.bsTypologiesFromCRM.asObservable();
  /**
   * Observable con información de Tipos Documentos
   */
  public readonly typeDocuments$ = this.bsTypeDocuments.asObservable();
  /**
   * Observable con la información del las disponibilidades para mañana
   */
  public readonly totalTomorrow$ = this.bsTotalTomorrow.asObservable();
  /**
   * Observable con la información del las disponibilidades para esta semana
   */
  public readonly totalItWeek$ = this.bsTotalItWeek.asObservable();
  /**
   * Observable con la información del las disponibilidades para este año
   */
  public readonly totalItYear$ = this.bsTotalItYear.asObservable();
  /**
   * Observable con la información del las disponibilidades para este mes
   */
  public readonly totalItMonth$ = this.bsTotalItMonth.asObservable();
  /**
   * Observable con la información de viabilidad
   */
  public readonly viability$ = this.bsViability.asObservable();

  /**
   * Crea una nueva instancia de AapModuleState
   */
  public constructor() {
    super();
  }
  /**
   * Inicializa el estado interaction en valores nulos
   */
  public resetInteraction() {
    this.next(initAgentInteraction);
  }
  /**
   * Metodo para almacenar en el estado la Ip en el objeto del agendamiento
   * @param ip Ip del equipo donde se ejecuta la app
   */
  public setIp(ip: string) {
    const state = this.value;
    state.payloadScheduling.ipPc = ip;
    this.next(state);
  }
  /**
   * Metodo para almacenar la data del payload de la interaction
   * @param payload Estructura de la data del agendamiento
   */
  public setPayload(payload: PayloadScheduling) {
    const state = this.value;
    state.payloadScheduling = payload;
    this.next(state);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información del estado
   * @param stateLive Listado de estados de agendamiento
   */
  public setStateLive(stateLive: StateSchedulingDto[]) {
    const state = this.value;
    const stateCurrent: StateSchedulingDto | undefined = stateLive.find((x) => x.id === 1);
    state.payloadScheduling.stateScheduling = {
      id: stateCurrent?.id || 0,
      name: stateCurrent?.name || '',
    };
    this.next(state);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información del canal
   * @param channel Listado de canales
   */
  public setChannel(channel: ChannelDto[]) {
    const state = this.value;
    const channelCurrent = channel.find((x) => x.id === 1);
    state.payloadScheduling.idChannel = channelCurrent?.id || 0;
    state.payloadScheduling.channel = {
      id: channelCurrent?.id ?? 1,
      name: channelCurrent?.name ?? 'Web',
    };
    this.next(state);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información del contacto
   *  y la fecha en que se realizó la interacción
   * @param contactInteraction Información del contacto
   */
  public setContact(contactInteraction: AgentInteraction) {
    this.next(contactInteraction);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información del asistente
   * @param contact Información del contacto - asistente
   */
  public setAssitent(contact: Contact) {
    const state = this.value;
    state.payloadScheduling.assitent = _.cloneDeep(contact);
    this.next(state);
  }
  /**
   * Metodo encargado de asignar la información del formulario "tu" a la interacción
   * @param contactInteraction Información del contacto @see AgentInteraction
   */
  public setContactFormPersonalize(contactInteraction: AgentInteraction) {
    this.next(contactInteraction);
  }
  /**
   * Metodo encargado de asignar la información del formulario "tu" a la interacción
   * @param contactInteraction Información del contacto @see AgentInteraction
   */
  public setContactFormYou(contactInteraction: AgentInteraction) {
    this.next(contactInteraction);
  }
  /**
   * Metodo encargado de asignar la información del formulario "otros" a la interacción
   * @param contactInteraction Información del contacto @see AgentInteraction
   */
  public setContactFormOther(contactInteraction: AgentInteraction) {
    this.next(contactInteraction);
  }
  /**
   * Metodo encargado de asignar la información del agendamiento
   * @param data Información del agendamiento @see DataScheduleCurrent
   */
  public setSchedule(data: DataScheduleCurrent) {
    const state = this.value;
    const date = moment(data?.metaData?.dateSchedullingInitial).locale('es').format('YYYY-MM-DD[T]HH:mm:ss');
    state.payloadScheduling.dateScheduling = date;
    state.payloadScheduling.timeScheduling = date;
    state.payloadScheduling.userCreator = {
      idServant: 0,
      idPosition: 0,
      position: 'N',
      id: 0,
      fullName: 'N',
      office: {
        idChannel: 1, channel: 'Web', regional: '0', idRegional: 0, name: 'Sitio Web',
      },
    };
    state.payloadScheduling.userGestorAssigned = {
      idServant: data.metaData?.idServant,
      fullName: data.metaData?.fullNameServant,
      idPosition: data.idPosition,
      position: data.position,
      office: data.office,
    };
    state.payloadScheduling.office = data.office;
    // State.payloadScheduling.typify = data.typify;
    this.next(state);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información del cargo del funcionario
   * @param position Data del cargo
   */
  public setSchedulePosition(position: PositionDto) {
    const state = this.value;

    if (state.payloadScheduling.userGestorAssigned) {
      state.payloadScheduling.userGestorAssigned.idPosition = position.id;
      state.payloadScheduling.userGestorAssigned.position = position.name;
    }
    this.next(state);
  }
  /**
   * Metodo encargado de asignar en la data del agendamiento la información de la tipologia
   * @param typology tipologia
   */
  public setTypology(typology: Typify) {
    const state = this.value;
    state.payloadScheduling.typify = _.cloneDeep(typology);
    this.next(state);
  }
  /**
   * Actualiza los valores para disponibilidad de citas
   */
  public setAvailableEngine(resultAvailability: AvailabilityDto[] | undefined) {
    this.bsAvailabilities.next(resultAvailability);
  }
  /**
   * Actualiza los valores en iniciales de disponibilidad de citas
   */
  public resetAvalilables() {
    this.bsAvailabilities.next([]);
  }
  /**
   * Metodo encargado de asignar los datos basicos de afiliado en el localStorage
   * @param basicDataAfiliate modelo dto de datos basicos de afiliado
   */
  public setBasicDataAfiliate(basicDataAfiliate: BasicDataAffiliateDto) {
    // eslint-disable-next-line
    if (basicDataAfiliate.datosBasicos !== null && basicDataAfiliate.datosBasicos !== undefined &&
      (localStorage.getItem('clientName') === '' || localStorage.getItem('clientName') === null)) {
      localStorage.setItem('primerNombre', JSON.stringify(basicDataAfiliate.datosBasicos?.primerNombre));
      localStorage.setItem('segundoNombre', JSON.stringify(basicDataAfiliate.datosBasicos?.segundoNombre));
      localStorage.setItem('primerApellido', JSON.stringify(basicDataAfiliate.datosBasicos?.primerApellido));
      localStorage.setItem('segundoApellido', JSON.stringify(basicDataAfiliate.datosBasicos?.segundoApellido));
      localStorage.setItem('clientName', JSON.stringify(basicDataAfiliate.datosBasicos?.nombresApellidosCompletos) || '');
    }
    this.bsBasicDataAffiliate.next(basicDataAfiliate);
  }
  /**
   * Metodo encargade de almacenar la informacion de los detalles de motivos
   * de los estados de agendamiento
   * @param data informacion de los detalles de motivos de los estados de agendamiento
   */
  public setDetailReasonStateScheduling(data: DetailReasonStateSchedulingDto[]) {
    this.bsDetailReasonStateScheduling.next(data);
  }
  /**
   * Metodo de actualiza los valores de las ciudades en el stream
   * @param cities Ciudades mas recientes en el stream
   */
  public setCities(cities: CityDto[]) {
    this.bsCities.next(cities);
  }
  /**
   * Metodo de actualiza los valores de las ciudades en el stream por KeyAndValue
   * @param cities Ciudades mas recientes en el stream
   */
  public setCitiesKeyAndValue(cities: { [id: number]: string }) {
    this.bsCitiesKey.next(cities);
  }
  /**
   * Metodo de actualiza los valores de las ciudades en el stream
   * @param cities Ciudades mas recientes en el stream
   */
  public setCitiesOne(cities: CityDto[]) {
    this.bsCitiesOne.next(cities);
  }
  /**
   * Metodo de actualiza los valores de las ciudades en el stream
   * @param cities Ciudades mas recientes en el stream
   */
  public setCitiesAll(cities: CityDto[]) {
    this.bsCitiesAll.next(cities);
  }
  /**
   * Metodo de actualiza los valores de los Paises en el stream
   * @param countries Paises mas recientes en el stream
   */
  public setCountries(countries: CountryDto[]) {
    this.bsCountries.next(countries);
  }
  /**
   * Actualiza los datos de la cuenta
   * @param dataAccount Datos de la cuenta
   */
  public setDataAccount(dataAccount: AccountDataDto) {
    this.bsDataAccount.next(dataAccount);
  }
  /**
   * Metodo de actualiza los valores del detalle de la estructura de tipologia
   * @param detailStructureTypology Detalle de la estructura de tipologia mas reciente en el stream
   */
  public setDetailStructureTypologies(detailStructureTypology: PayloadTypologySettingsDto[]) {
    this.bsDetailStructureTypologies.next(detailStructureTypology);
  }
  /**
   * Actualiza el valor del detalle de la tipologia seleccionada
   * @param detail Tipologia seleccionada
   */
  public setDetailTypologySelected(detail: PayloadTypologySettingsDto) {
    this.bsDetailTypologySelected.next(detail);
  }
  /**
   * Metodo de actualiza los valores de los departamentos en el stream
   * @param departments Departamentos mas recientes en el stream
   */
  public setDepartments(departments: DepartmentDto[]) {
    this.bsDepartments.next(departments);
  }
  /**
   * Metodo de actualiza los valores de los departamentos en el stream
   * @param departments Departamentos mas recientes en el stream
   */
  public setDepartmentsAll(departments: DepartmentDto[]) {
    this.bsDepartmentsAll.next(departments);
  }
  /**
   * Metodo de actualiza los valores de los departamentos en el stream
   * @param data Departamentos en key/value
   */
  public setDepartmentsKeyAndValue(data: { [id: number]: string }) {
    this.bsDepartmentsKey.next(data);
  }
  /**
   * Actualiza los valores de los generos en el stream
   * @param data Generos mas recientes en el stream
   */
  public setGendersKeyAndValue(data: { [id: number]: string }) {
    this.bsGendersKey.next(data);
  }
  /**
   * Metodo para almacenar el valor seleccionado por el usuario para habeas data
   * @param indicator Valor de la bandera de habeas data
   */
  public setHabeasData(indicator: boolean) {
    this.bsHabeasData.next(indicator);
  }
  /**
   * Metodo de actualiza los valores de los ids de canal en el stream
   * @param data Ids de canales que son capturados al seleccionar el canal
   */
  public setIdsChannels(data: number[]) {
    this.bsIdChannels.next(data);
  }
  /**
   * Metodo que actualiza el id de solicitud de agenda
   * @param idSolicitud Id interaction de agendamiento
   */
  public setIdSolicitud(idSolicitud: number) {
    this.bsIdSolicitud.next(idSolicitud);
  }
  /**
   * Actualiza el indicador de agenda
   * @param indicator Indicador de agendamiento
   */
  public setIndicator(indicator: string) {
    this.bsIndicator.next(indicator);
  }
  /**
   * Metodo que actualiza el valor de la bandera de la doble asesoria
   * @param indicador Valor de la bandera de doble asesoria
   */
  public setIndicadorDobleAsesoria(indicador: boolean) {
    this.bsIndicadorDobleAsesoria.next(indicador);
  }
  /**
   * Metodo que actualiza el valor de la bandera de la afiliación del contacto
   * @param isAffiliate Valor de la bandera de afiliación
   */
  public setIsAffiliate(isAffiliate: boolean) {
    this.bsIsAffiliate.next(isAffiliate);
  }
  /**
   * Metodo que actualiza el valor de la bandera de la viabilidad del contacto
   * @param isViable Valor de la bandera de viabilidad
   */
  public setIsViable(isViable: boolean) {
    this.bsIsViable.next(isViable);
  }
  /**
   * Metodo para almacena el payload de la interaction temporal en modificar
   * @param payload Estructura de la data del agendamiento
   */
  public setModifyPayload(payload: PayloadScheduling) {
    this.bsPayloadSchedulingModify.next(payload);
  }
  /**
   * Metodo de actualiza el valor de los nodos (oficinas)
   * @param node Listado de nodos (oficinas)
   */
  public setNodes(node: NodeDto[]) {
    this.bsNodes.next(node);
  }
  /**
   * Metodo de actualiza el valor de las oficinas
   * @param node oficinas
   */
  public setNodesAll(node: NodeDto[]) {
    this.bsNodesAll.next(node);
  }
  /**
   * Guarda la información de la oficina
   * @param officeId información de la oficina
   */
  public setOfficeId(officeId: NodeDto) {
    this.bsOfficeId.next(officeId);
  }
  /**
   * Actualiza el valor de cargos por tipos de tipologias
   * @param positionTypeTypology Información de cargos por tipos de tipologias
   */
  public setPositionByTypeTypologies(positionTypeTypology: PositionTypeTypologyDto[]) {
    this.bsPositionTypeTypologies.next(positionTypeTypology);
  }
  /**
   * Actualiza el valor del motivo de la tipologia seleccionada
   * @param reasonTypologySelected Motivo dce Tipologia seleccionada
   */
  public setReasonTypologySelected(reasonTypologySelected: DoubleAdvisory) {
    this.bsReasonTypologySelected.next(reasonTypologySelected);
  }
  /**
   * Metodo de actualiza los valores de las ciudades en el stream
   * @param cities Ciudades mas recientes en el stream
   */
  public setResidentialCities(cities: CityDto[]) {
    this.bsResidentialCities.next(cities);
  }
  /**
   * Metodo encargado de almacenar la informacion de los agendamientos
   * @param data Listado de los agendamientos
   */
  public setSchedules(data: ScheduleDto[]) {
    this.bsSchedule.next(data);
  }
  /**
   * Metodo encargado de almacenar la informacion de los agendamientos del asistente
   * @param data Listado de los agendamientos
   */
  public setSchedulesAssitant(data: ScheduleDto[]) {
    this.bsScheduleAssistant.next(data);
  }
  /**
   * Actualiza los valores de la información adicional del contacto al validar la tipologia
   * @param response Información adicional
   */
  public setScheduleClaim(response: ObjectResponse) {
    this.bsScheduleClaim.next(response);
  }
  /**
   * Metodo encargado de almacenar la informacion del agendamiento
   * @param data informacion del agendamiento
   */
  public setSchedulesId(data: ScheduleDto) {
    this.bsScheduleId.next(data);
  }
  /**
   * Metodo encargade de almacenar la informacion de los agendamientos por tipologia
   * @param data informacion del agendamiento
   */
  public setSchedulesTypology(data: ScheduleDto[]) {
    this.bsScheduleTypology.next(data);
  }
  /**
   * Actualiza el valor agenda lista
   * @param infoCita Información de agenda
   */
  public setScheduleList(infoCita: DataScheduleCurrent[]) {
    this.bsScheduleList.next(infoCita);
  }
  /**
   * Metodo encargado de almacenar la información de los estados de agendamiento
   * @param data informacion del agendamiento
   */
  public setSchedulesState(data: StateSchedulingDto[]) {
    this.bsScheduleState.next(data);
  }
  /**
   * Guarda la información de la oficina
   * @param servant información de la oficina
   */
  public setServant(servant: ServantDto) {
    this.bsServant.next(servant);
  }
  /**
   * Metodo de actualiza el valor de la estructura de tipologia
   * @param structureTypology Estructura de tipologia mas recientes en el stream
   */
  public setStructureTypology(structureTypology: StructureTypologyDto) {
    this.bsStructureTypology.next(structureTypology);
  }
  /**
   * Metodo de actualiza el valor de semanas cotizadas
   * @param data Estructura de datos para el segmentador
   */
  public setSegmenterData(data: DataSegmenterDto) {
    this.bsSegmenter.next(data);
  }
  /**
   * Metodo de actualizar los tipos de tipologias
   * @param typeTypology Tipos de tipologias mas recientes en el stream
   */
  public setTypeTypologies(typeTypology: TypeTypologyDto[]) {
    this.bsTypeTypologies.next(typeTypology);
  }
  /**
   * Actualiza el valor de los tipos de asistente de la tipologia seleccionada
   * @param typesAttendee Tipos de asistente de la tipologia seleccionada
   */
  public setTypesAttendeeTypologySelected(typesAttendee: TypesAttendeeDto[]) {
    this.bsTypesAttendeeTypologySelected.next(typesAttendee);
  }
  /**
   * Metodo de actualiza los valores de tipologias de contacto del ws datos basicos
   * @param Typology tipologias del contacto
   */
  public setTypologiesFromCRM(Typology: TypologyDto[]) {
    this.bsTypologiesFromCRM.next(Typology);
  }
  /**
   * Actualiza los valores de los tipos de documentos en el stream
   * @param typeDocuments Tipos de documentos mas recientes en el stream
   */
  public setTypeDocuments(typeDocuments: TypeDocumentDto[]) {
    this.bsTypeDocuments.next(typeDocuments);
  }
  /**
   * Metodo de actualiza el listado de las tipologias segun el tipo de tipología
   * @param PayloadTypologies Objeto de las tipologias obtenidas en la consulta de la facade
   */
  public setTypologiesByTypeFilter(PayloadTypologies: PayloadTypologySettingsDto[]) {
    this.bsTypologiesFilter.next(PayloadTypologies);
  }
  /**
   * Actualiza el valor de la tipologia seleccionada
   * @param typologySelected Tipologia seleccionada
   */
  public setTypologySelected(typologySelected: Typify) {
    this.bsTypologySelected.next(typologySelected);
  }
  /**
   * Actualiza el valor de las disponibilidades de mañana
   * @param totalTomorrow Información de las disponibilidades de mañana
   */
  public setTotalTomorrow(totalTomorrow: AvailabilityDto[] | undefined) {
    this.bsTotalTomorrow.next(totalTomorrow);
  }
  /**
   * Actualiza el valor de las disponibilidades de esta semana
   * @param totalItWeek Información de las disponibilidades de esta semana
   */
  public setTotalItWeek(totalItWeek: AvailabilityDto[] | undefined) {
    this.bsTotalItWeek.next(totalItWeek);
  }
  /**
   * Actualiza el valor de las disponibilidades de esta año
   * @param totalItYear Información de las disponibilidades de este año
   */
  public setTotalItYear(totalItYear: AvailabilityDto[] | undefined) {
    this.bsTotalItYear.next(totalItYear);
  }
  /**
   * Actualiza el valor de las disponibilidades de esta semana
   * @param totalMonth Información de las disponibilidades de mes
   */
  public setTotalItMonth(totalMonth: AvailabilityDto[] | undefined) {
    this.bsTotalItMonth.next(totalMonth);
  }
  /**
   * Metodo que actualiza la informacion de la viabilidad del contacto
   * @param viability Informacion de la viabilidad del contacto
   */
  public setViability(viability: ResponseDto) {
    // eslint-disable-next-line
    if (viability.viabilidad !== undefined && viability.viabilidad !== null &&
      (localStorage.getItem('clientName') === '' || localStorage.getItem('clientName') === null)) {
      localStorage.setItem('primerNombre', JSON.stringify(viability.viabilidad?.primerNombre));
      localStorage.setItem('segundoNombre', JSON.stringify(viability.viabilidad?.segundoNombre));
      localStorage.setItem('primerApellido', JSON.stringify(viability.viabilidad?.primerApellido));
      localStorage.setItem('segundoApellido', JSON.stringify(viability.viabilidad?.segundoApellido));
      // eslint-disable-next-line prefer-template
      const nombres = JSON.stringify(viability.viabilidad?.nombres) + ' ' + JSON.stringify(viability.viabilidad?.apellidos);
      localStorage.setItem('clientName', nombres);
    }
    this.bsViability.next(viability);
  }
  /**
   * Gets bsAvailabilities Observable
   */
  public get availabilitiesObject() {
    return this.bsAvailabilities.value;
  }
  /**
   * Gets basicDataAffiliate Observable
   */
  public get basicDataAffiliateObject() {
    return this.bsBasicDataAffiliate.value;
  }
  /**
   * Get last value cities
   */
  public get citiesObject() {
    return this.bsCities.value;
  }
  /**
   * Get last value cities
   */
  public get citiesAllObject() {
    return this.bsCitiesAll.value;
  }
  /**
   * Get last value department
   */
  public get departmentObject() {
    return this.bsDepartments.value;
  }
  /**
   * Get last value department
   */
  public get departmentAllObject() {
    return this.bsDepartmentsAll.value;
  }
  /**
   * Get last value detailStructureTypologies
   */
  public get detailStructureTypologiesObject() {
    return this.bsDetailStructureTypologies.value;
  }
  /**
   * Gets Detalle de la tipologia seleccionada Observable
   */
  public get detailTypologySelectedObject() {
    return this.bsDetailTypologySelected.value;
  }
  /**
   * Gets id solicitud Observable
   */
  public get idSolicitudObject() {
    return this.bsIdSolicitud.value;
  }
  /**
   * Gets Indicator Observable
   */
  public get indicatorObject() {
    return this.bsIndicator.value;
  }
  /**
   * Gets Indicator Doble Asesoria Observable
   */
  public get indicatorDobleAsesoriaObject() {
    return this.bsIndicadorDobleAsesoria.value;
  }
  /**
   * Get last value isAffiliate
   */
  public get isAffiliateBit() {
    return this.bsIsAffiliate.value;
  }
  /**
   * Get last value isViable
   */
  public get isViableBit() {
    return this.bsIsViable.value;
  }
  /**
   * Get last value nodes
   */
  public get nodesObject() {
    return this.bsNodes.value;
  }
  /**
   * Get last value positionTypeTypologyBehaviorSubject
   */
  public get positionTypeTypology() {
    return this.bsPositionTypeTypologies.value;
  }
  /**
   * Get last value position
   */
  public get positionObject() {
    return this.bsServant.value;
  }
  /**
   * Get last value payloadSchedulingModify
   */
  public get payloadSchedulingModifyObject() {
    return this.bsPayloadSchedulingModify.value;
  }
  /**
   * Get last value ReasonTypologySelected
   */
  public get reasonTypologySelectedObject() {
    return this.bsReasonTypologySelected.value;
  }
  /**
   * Get last value schedules
   */
  public get schedulesObject() {
    return this.bsSchedule.value;
  }
  /**
   * Get last value scheduleState
   */
  public get scheduleStateObject() {
    return this.bsScheduleState.value;
  }
  /**
   * Get last value statelive
   */
  public get stateLive() {
    return this.bsScheduleState.value;
  }
  /**
   * Get last value structureTypology
   */
  public get structureTypologyObject() {
    return this.bsStructureTypology.value;
  }
  /**
   * Get last value scheduleClaim
   */
  public get scheduleClaimObject() {
    return this.bsScheduleClaim.value;
  }
  /**
   * Get last value scheduleList
   */
  public get scheduleListObject() {
    return this.bsScheduleList.value;
  }
  /**
   * Get last value typeDocuments
   */
  public get typeDocumentsObject() {
    return this.bsTypeDocuments.value;
  }
  /**
   * Get last value typologiesFilter
   */
  public get typologiesFilterObject() {
    return this.bsTypologiesFilter.value;
  }
  /**
   * Get last value typologiesFromCRM
   */
  public get typologiesFromCRMObject() {
    return this.bsTypologiesFromCRM.value;
  }
  /**
   * Get last value typologySelected
   */
  public get typologySelectedObject() {
    return this.bsTypologySelected.value;
  }
  /**
   * Get last value viability
   */
  public get viabilityObject() {
    return this.bsViability.value;
  }
  /**
  * Get last value segmenter
  */
  public get segmenterObject() {
    return this.bsSegmenter.value;
  }
}
