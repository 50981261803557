import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//import { CustomModalComponent } from 'apps/website/src/app/shared/dialogs/custom-modal/custom-modal.component';
//import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { ConfigModalsAssignmentValidation } from '../../shared/dialogs/custom-modal/config/assignment/config-modals-validation';
import { ConfigModalsValidation } from '../../shared/dialogs/custom-modal/config/validation/config-modals-validation';
/**
 * Componente que permite al usuario seleccionar la operación a realizar
 */
@Component({
  selector: 'prv-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  /**
   * Permite desplegar un modal
   */
  public dialog: MatDialog;
  /**
   * Permite detectar la plataforma
   */
  //public deviceService: DeviceDetectorService;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsAssignmentValidation: ConfigModalsAssignmentValidation;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsValidation: ConfigModalsValidation;
  /**
   * Dispositivo
   */
  //private deviceInfo!: DeviceInfo;
  /**
   * Crea una nueva instancia WelcomeComponent
   */
  public constructor(
    dialog: MatDialog,
    //deviceService: DeviceDetectorService,
  ) {
    this.dialog = dialog;
    //this.deviceService = deviceService;
    this.configModalsAssignmentValidation = new ConfigModalsAssignmentValidation();
    this.configModalsValidation = new ConfigModalsValidation();
  }
  /**
   * It is invoked only once when the directive is instantiated.
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {
    //this.OnNavigate();
  }
  /**
   * Metodo encargado de identificar el dispositivo
   * console.log(this.deviceInfo);
   */
  /*private OnNavigate() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.os === 'iOS' || this.deviceInfo.os === 'Mac') {
      const currentDialog = this.dialog.open(CustomModalComponent, {
        data: this.configModalsAssignmentValidation.modalBrowserOpera(),
        width: '720px',
        height: '350px',
        panelClass: 'dialog-container',
        autoFocus: false,
        restoreFocus: false,
        disableClose: true,
      });
      currentDialog.beforeClosed().subscribe(() => {
        window.location.href = 'https://www.porvenir.com.co/web/personas/inicio';
      });
    }
  }*/
}
