import { Component, OnInit } from '@angular/core';

/**
 * Componente encargado de administrar los errores
 */
@Component({
  selector: 'prv-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  /**
   * It is invoked only once when the directive is instantiated.
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}
}
