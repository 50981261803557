import { Component, OnInit } from '@angular/core';

/**
 * Componente encargado de mostarar al usuario que no se encuentra autorizado
 */
@Component({
  selector: 'prv-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  /**
   * It is invoked only once when the directive is instantiated.
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}
}
