import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleDto } from '@prv/schedule-history';
import { TypeDocumentDto } from '@prv/type-document';
import { CustomModalComponent } from 'apps/website/src/app/shared/dialogs/custom-modal/custom-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from '../../core/services/auth/authentication.service';
import { ValidatorMessageService } from '../../core/services/validator-message/validator-message.service';
import { ConfigModalsAssignmentValidation } from '../../shared/dialogs/custom-modal/config/assignment/config-modals-validation';
import { ConfigModalsValidation } from '../../shared/dialogs/custom-modal/config/validation/config-modals-validation';
import { ITypeMessage } from '../../shared/models/type-message.model';
import { ValidationFacade } from '../facade/validation.facade';
import { takeUntil } from 'rxjs/operators';

/**
 * Componente encargado de validar e iniciar el proceso de autenticación
 */
@Component({
  selector: 'prv-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit, OnDestroy {
  /**
   * Permite detectar la plataforma
   */
  //public deviceService: DeviceDetectorService;
  /**
   * Dispositivo
   */
  //private deviceInfo!: DeviceInfo;
  /**
   * Autenticación de usuario
   */
  public get isAuthenticated() {
    return this.authService.isAuthenticated;
  }
  /**
   * Affiliate de usuario
   */
  public get isAffiliate() {
    return this.authService.isAffiliate;
  }
  /**
   * Viable de usuario
   */
  public get isViable() {
    return this.authService.isViable;
  }
  /**
   * Servicio pantalla carga
   */
  private readonly spinner: NgxSpinnerService;
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;
  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly activatedRoute: ActivatedRoute;
  /**
   * Permite desplegar un modal
   */
  public dialog: MatDialog;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsAssignmentValidation: ConfigModalsAssignmentValidation;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsValidation: ConfigModalsValidation;
  /**
   * Capa intermedia que centraliza la logica del agente
   */
  private readonly facade: ValidationFacade;
  /**
   * Servicio encargado de controlar la autenticación
   */
  private readonly authService: AuthenticationService;
  /**
   * Observable History
   */
  public readonly schedule$: Observable<ScheduleDto[]>;
  /**
   * Observable Types Documents
   */
  public readonly typeDocuments$: Observable<TypeDocumentDto[]>;
  /**
   * Observable para destruir las suscripciones
   */
  private readonly destroy$ = new Subject();
  /**
   * Url desde donde fue invocado
   */
  private readonly url: string;
  /**
   * Formulario de Validación
   */
  public form: UntypedFormGroup;
  /**
   * listado de mensajes de error
   */
  public typeMessages: ITypeMessage[];
  /**
   * Objecto tipo documento
   */
  public typeDocument: TypeDocumentDto | undefined = { regex: ''};
  /**
   * Almaccenar el observable
   */
  public datos: any[];
  /**
   * Almaccenar el regex
   */
  // public regex = '';

  /**
   * Crea una nueva instancia de ValidationComponent
   */
  public constructor(
    spinner: NgxSpinnerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    dialog: MatDialog,
    facade: ValidationFacade,
    authService: AuthenticationService,
    typeMessages: ValidatorMessageService,
    builder: UntypedFormBuilder,
    //deviceService: DeviceDetectorService,
  ) {
    this.spinner = spinner;
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.dialog = dialog;
    this.facade = facade;
    this.configModalsAssignmentValidation = new ConfigModalsAssignmentValidation();
    this.configModalsValidation = new ConfigModalsValidation();
    this.authService = authService;
    this.typeMessages = typeMessages.typeMessage;
    this.url = router.url;
    this.datos = [];
    this.schedule$ = this.facade.schedule$();
    this.typeDocuments$ = this.facade.typeDocuments$();
    //this.deviceService = deviceService;

    this.form = builder.group({
      typeDocument: [null, Validators.required],
      numberDocument: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(20)],
      ],
      captcha: [null, Validators.required],
    });
    switch (this.url) {
      case '/validation-search':
        /* This.SendFailOtp(); */
        break;
      case '/validation-modify-cancel':
        /* This.SendFailOtp(); */
        break;
      default:
    }
  }

  /**
   * It is invoked only once when the directive is instantiated.
   */
  public ngOnInit(): void {
    //this.OnNavigate();
    this.facade.getTempTypeDocuments((response) => {
      this.facade.setTypeDocumentsSt(response);
    });
    this.facade.getIP();
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately
   * before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Obtiene el objecto seleccionado y cambia el regex
   * @param id Id del tipo documento
   */
  public onChangeSelect(id: number) {
    this.spinner.show();
    this.facade.getTypeDocumentsByIdSt(id, (response) => {
      this.typeDocument = response;
      this.spinner.hide();
    });
  }

  /**
   * Evento para navegar a la pagina anterior
   */
  public onBack(): void {
    this.router.navigate(['/']);
  }

  /**
   * Convierte string a json
   * @param data que recibe una cadena
   */
  public parseJson(data: string) {
    return JSON.parse(data);
  }

  /**
   * Evento encargado de procesar el formulario
   */
  public onSubmit() {
    this.spinner.show();
    const data = this.form.value;
    let typeDocumentCurrent : any;

    // Obtiene el tipo de documento del afiliado
    this.typeDocuments$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      typeDocumentCurrent = response.filter(x => x.id == data.typeDocument)
    });

    localStorage.setItem(`typeDocument`, typeDocumentCurrent[0].mask);
    localStorage.setItem(`documentNumber`, data.numberDocument);
    localStorage.setItem(`clientName`, '');
    this.facade.resetInteraction();
    this.facade.authenticateClientAuth(data, this.typeDocument, this.url, (response) => {
      this.spinner.hide();
      this.facade.ngOnDestroy();
      if (response.objModal !== undefined) {
        this.dialog.open(CustomModalComponent, response.objModal);
        this.router.navigate(response.routes);
      } else {
        this.router.navigate(response.routes, { relativeTo: this.activatedRoute });
      }
      this.form.reset();
    });
  }
}
