import { Injectable } from '@angular/core';

import { ITypeMessage } from '../../../shared/models/type-message.model';

/**
 * Servicio encargado de administrar los tipos de do
 */
@Injectable()
export class ValidatorMessageService {
  /**
   * tipo de errores
   */
  public typeMessage: ITypeMessage[] = [
    {
      type: 'required',
      message: '¡Este campo es obligatorio!',
    },
    {
      type: 'minlength',
      message: '¡No cumple la longitud minima!',
    },
    {
      type: 'maxlength',
      message: '¡Este campo execede la longitud maxima!',
    },
    {
      type: 'pattern',
      message: '¡Este campo no contiene el formato correcto!',
    },
    {
      type: 'min',
      message: '¡El contenido del campo debe ser mayor a cero!',
    },
    {
      type: 'phonevalid',
      message: '¡Este campo no contiene el formato correcto!',
    },
  ];
}
