import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './components/error/error.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ValidationComponent } from './components/validation/validation.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';

/**
 * Matriz de rutas
 */
const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
  },
  {
    path: 'validation-assignment',
    component: ValidationComponent,
  },
  {
    path: 'validation-modify-cancel',
    component: ValidationComponent,
  },
  {
    path: 'validation-search',
    component: ValidationComponent,
  },
  {
    path: 'assignment-module',
    loadChildren: () => import('./modules/assignment/assignment.module').then((x) => x.AssignmentModule),
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'search-module',
    loadChildren: () => import('./modules/search/search.module').then((x) => x.SearchModule),
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'modifycancel-module',
    loadChildren: () => import('./modules/modify-cancel/modify-cancel.module').then((x) => x.ModifyCancelModule),
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'assistant',
    loadChildren: () => import('./modules/required/required.module').then((x) => x.RequiredModule),
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

/**
 * Modulo encargado de administrar las rutas base del sistema
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
