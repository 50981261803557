<div *ngIf="typeHeader === 'initial'; else second">
  <div class="container-banner">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlexAlign="center start">
        <img class="img-logo" src="../../../../assets/images/logo_login.png" />
      </div>
      <p
        fxFlexAlign="center center"
        fxFlexOffset.xs="12"
        fxFlexOffset.sm="8"
        fxFlexOffset.md="12"
        fxflex.md="10"
        fxFlexOffset.lg="15"
        fxflex.lg="10"
        fxFlexOffset.xl="15"
        fxflex.xl="10"
        class="title-central"
      >
        Agenda tu cita con nosotros
      </p>
      <p fxFlexAlign="center end" class="title-end">#AvancemosJuntos</p>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayout.sm="column">
    <div fxflex class="banner"></div>
  </div>
</div>
<ng-template #second>
  <div class="container-banner">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.md="space-between center">
      <div fxFlexAlign="center start" id="printTree">
        <img class="img-logo" src="../../../../assets/images/logo_login.png" />
      </div>
      <div
        fxFlexAlign="center center"
        fxFlexOffset.sm="15"
        fxFlexOffset.md="10"
        fxFlexOffset.lg="10"
        fxFlexOffset.xl="10"
        id="printFour"
      >
        <p class="title-central-final">Agenda tu cita con nosotros</p>
        <p class="title-end-final">#AvancemosJuntos</p>
      </div>
      <div fxFlexAlign="center end" fxHide.xs>
        <button class="button-continue" (click)="refresh()">
          <img src="../../../../assets/images/salir.png" class="control" />Salir del servicio
        </button>
      </div>
      <div fxFlexAlign="center end" fxShow.xs fxHide.sm fxHide.md fxHide.lg fxHide.xl>
        <button class="button-continue" (click)="refresh()">
          <img src="../../../../assets/images/salir.png" class="control" />Salir
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="banner" id="printFive">
    <p fxFlex fxFlexAlign="end" class="title-end-banner">{{ fullName }} | IP : {{ ipAddress }}</p>
  </div>
</ng-template>
