import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Componente encargado de mostarar al usuario que no se encuentra autorizado
 */
@Component({
  selector: 'prv-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {

  /**
   * Provides access to information about a route associated with a component that is loaded in an outlet.
   */
  private readonly router: Router;

  /**
   * Crea una nueva instancia de ValidationComponent
   */
  public constructor(router: Router) {
    this.router = router;

  }
  /**
   * It is invoked only once when the directive is instantiated.
   */
  public ngOnInit(): void {
    setTimeout(() => this.router.navigate(['/']), 5000);
  }
}
