import { Injectable, OnDestroy } from '@angular/core';
import { NodeDto } from '@prv/georeference';
import { PayloadScheduling, ScheduleDto } from '@prv/schedule-history';
import { DetailReasonStateSchedulingApiService, DetailReasonStateSchedulingDto, ReasonStateSchedulingDto } from '@prv/scheduling-state';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppModuleState } from '../../../app.state';

/**
 * Capa intermedia que centraliza la logica del modulo de consulta
 */
@Injectable()
export class ModifyCancelFacade implements OnDestroy {
  /**
   * State de schedule
   */
  private readonly state: AppModuleState;
  /**
   * Informacion de los estados
   */
  private readonly detailReasonStateSchedulingApiService: DetailReasonStateSchedulingApiService;
  /**
   * cancelar suscripciones
   */
  private readonly subscription: Subscription;
  /**
   * Observable encargado de eliminar los diferentes sucriptores
   */
  public destroy$ = new Subject<boolean>();
  /**
   * Observable de schedules
   */
  public readonly scheduleId$ = (): Observable<ScheduleDto> => this.state.scheduleId$;
  /**
   * Observable de DetailReasonStateScheduling
   */
  public readonly detailReasonStateScheduling$ = (): Observable<DetailReasonStateSchedulingDto[]> =>
    this.state.detailReasonStateScheduling$
  /**
   * Observable de Nodos todos
   */
  public readonly nodesAll$ = (): Observable<NodeDto[]> => this.state.nodesAll$;
  /**
   * Crea una nueva instancia de SearchFacade
   */
  public constructor(
    state: AppModuleState,
    detailReasonStateSchedulingApiService: DetailReasonStateSchedulingApiService,
  ) {
    this.state = state;
    this.detailReasonStateSchedulingApiService = detailReasonStateSchedulingApiService;
    this.subscription = new Subscription();
  }
  /**
   * A callback method that performs custom clean-up, invoked immediately
   * before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroySubscription();
    setTimeout(() => {
      this.destroy$.next();
      this.destroy$.complete();
    }, 3000);
  }
  /**
   * Metodo encargado de desuscribir los observables de la facade
   */
  public destroySubscription(): void {
    this.subscription.unsubscribe();
  }
  /**
   * Asignación de citas
   */
  public get state$() {
    return this.state.agentInteractionState$;
  }
  /**
   * Metodo que obtiene los estados de cancelacion con el id 3
   * @param fn Funcion CallBack @see ReasonStateSchedulingDto[]
   */
  public getscheduleStates(fn: (response: ReasonStateSchedulingDto[]) => void) {
    this.detailReasonStateSchedulingApiService.GetByIdReasonStateScheduling({ idReasonStateScheduling: 3 })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        fn(response);
      });
  }
  /**
   * Metodo que obtiene del estado los agendamientos
   * @param fn Funcion CallBack @see ScheduleDto
   */
  public getSchedulesSt(fn: (response: ScheduleDto) => void) {
    this.subscription.add(this.state.scheduleId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        fn(response);
      }));
  }

  public setDetailReasonStateScheduling(data: ReasonStateSchedulingDto[]) {
    this.state.setDetailReasonStateScheduling(data);
  }
  /**
   * Almacena en el estado el payload de la interaction
   * @param payload recibe un modelo PayloadScheduling
   */
  public setPayload(payload: PayloadScheduling) {
    this.state.setPayload(payload);
    this.state.setModifyPayload(payload);
  }
}
