<div
  class="flex-container"
  fxLayout.xs="column wrap"
  fxLayout.sm="column wrap"
  fxLayout.lg="row wrap"
  fxLayoutAlign.xs = "center"
  fxLayoutAlign="space-evenly center"
  fxLayoutGap.sm="20px"
>
<!-- TEXTO DEFENSOR DEL CONSUMIDOR -->
  <div class="border" fxFlex.xs="100%" fxFlex.sm="10" fxFlex.md="30" fxFlex.lg="25" fxFlex.xl="30">
    <p class="p-footer">
      Cuentas con un <b>Defensor del Consumidor Financiero</b>, Dra. Ana María Giraldo Rincón, quien dará trámite a tu
      queja de forma objetiva y gratuita. <b class="link" (click)="openDataModal()">Ver toda la información.</b>
    </p>
  </div>
  <!-- RECOMENDACIONES NAVEGADOR -->
  <div
    class="border"
    fxFlex.xs="100%"
    fxFlex.sm="20"
    fxFlex.md="30"
    fxFlex.lg="20"
    fxFlex.xl="30"
    fxLayout.xs="column wrap"
    fxLayout.lg="column wrap"
    fxLayout.xl="column wrap"
    fxLayoutAlign="center"
  >
    <p class="p-footer center">
      Te recomendamos usar los siguientes navegadores para que tu experiencia con este servicio sea óptima
    </p>
    <div
      fxLayoutAlign="center"
      fxLayoutGap.xs="5px"
      fxLayoutGap.sm="20px"
      fxLayoutGap.md="20px"
      fxLayoutGap.lg="20px"
      fxLayoutGap.xl="20px"
    >
      <div fxLayout="column" fxFlex.xs="8" fxFlex.sm="7" fxFlex.md="7" fxFlex.lg="7" fxFlex.xl="5">
        <img class="icons" src="../../../../assets/images/IE-logo.png" alt="Microsoft Edge" />
        <p class="p-icons-footer">Edge</p>
      </div>
      <div fxLayout="column" fxFlex.xs="8" fxFlex.sm="7" fxFlex.md="7" fxFlex.lg="7" fxFlex.xl="5">
        <img class="icons" src="../../../../assets/images/chrome-logo.png" alt="Google Chrome" />
        <p class="p-icons-footer">50+</p>
      </div>
      <div fxLayout="column" fxFlex.xs="8" fxFlex.sm="7" fxFlex.md="7" fxFlex.lg="7" fxFlex.xl="5">
        <img class="icons" src="../../../../assets/images/firefox-logo.png" alt="Firefox" />
        <p class="p-icons-footer">50+</p>
      </div>
      <div fxLayout="column" fxFlex.xs="8" fxFlex.sm="7" fxFlex.md="7" fxFlex.lg="7" fxFlex.xl="5">
        <img class="icons" src="../../../../assets/images/safari-logo.png" alt="Safari" />
        <p class="p-icons-footer">50+</p>
      </div>
    </div>
  </div>
  <!-- LOGO SUPERINTENDENCIA -->
  <div
    class="border"
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex.sm="10"
    fxFlex.md="20"
    fxFlex.lg="20"
    fxFlex.xl="17"
    fxLayoutAlign="center"
  >
    <img
      class="vigilado"
      src="../../../../assets/images/superintendencia.png"
      alt="Superintendencia Financiera de Colombia"
    />
  </div>
</div>
