<!--BOTÓN DE CERRAR-->
<div *ngIf="config.closeCornerButton">
  <a class="button button-close" (click)="onSubmitClose(config.caseButtonClose)" mat-mini-fab mat-dialog-close></a>
</div>
<!--CONTENIDO DEL MODAL-->
<div fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" *ngIf="config.titleModal" class="title-modal">
    <h1>{{ config.titleModal }}</h1>
  </div>
  <div *ngIf="config.typeContentModal === 'text'; else template">
    <div fxLayout="row" *ngIf="config.contentImage && config.locationImage === 'top'" class="image-modal">
      <img class="custom-image" src="../../../../assets/images/{{ config.image }}" />
    </div>
    <div fxLayout="row" *ngIf="config.textContentModal" class="text-content-modal">
      <div [innerHTML]="config.textContentModal"></div>
    </div>
    <div fxLayout="row" *ngIf="config.contentImage && config.locationImage === 'bottom'" class="image-modal">
      <img class="custom-image" src="../../../../assets/images/{{ config.image }}" />
    </div>
  </div>
</div>
<!--BOTONES-->
<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="center center" class="buttons-modal">
  <button class="button button-back" mat-raised-button mat-dialog-close *ngIf="config.buttonBack">
    <img *ngIf="config.imageButtonBack" class="back-image" src="../../../../assets/images/volver.png" />
    <span class="textBack">{{ config.textBackButton }}</span>
  </button>
  <button
    class="button button-continue"
    mat-raised-button
    mat-dialog-close
    *ngIf="config.buttonNext"
    (click)="onSubmit(config.caseButtonNext)"
  >
    <span class="textContinue">{{ config.textNextButton }}</span>
    <img *ngIf="config.imageButtonNext" class="continue-image" src="../../../../assets/images/continuar.png" />
  </button>
</div>

<!--TEMPLATE PARA CONTENIDO DINAMICO-->
<ng-template #template>
  <div [ngSwitch]="config.typeTemplate">
    <div *ngSwitchCase="'cancel'">
      <form [formGroup]="form">
        <div class="content-campos" fxLayout="row">
          <label class="subtitle-modal">Cúentanos porque deseas cancelar tu cita:</label>
          <select class="select-campos" formControlName="optionCancel">
            <option *ngFor="let row of detailReasonStateScheduling$ | async; let i = index" [ngValue]="row">
              {{ row.name }}
            </option>
          </select>
        </div>
        <div class="content-campos" fxLayout="row">
          <div></div>
          <div *ngFor="let item of typeMessages">
            <span
              class="invalid"
              *ngIf="form.controls['optionCancel'].hasError(item.type) && form.controls['optionCancel'].touched"
              >{{ item.message }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div *ngSwitchDefault>
      <div></div>
    </div>
  </div>
</ng-template>
