<!-- cabecera -->
<prv-header typeHeader="initial"></prv-header>
<!-- contenedor -->
<div class="container">
  <!-- formulario-validacion -->
  <form [formGroup]="form">
    <!-- tipo-identificacion -->
    <div class="container-row mt-2">
      <div
        fxLayout="row"
        fxLayout.xs="row wrap"
        class="space"
        fxLayoutAlign.xs="space-evenly stretch"
        fxLayoutAlign="start center"
      >
        <label
          fxFlex.xs="100"
          fxFlexOffset.sm="14"
          fxFlexOffset.md="16"
          fxFlexOffset.lg="18"
          fxFlexOffset.xl="18"
          [ngClass.xs]="'mb-1'"
          >Tipo de identificación</label
        >
        <div fxLayout="row wrap" fxFlex.xs="100" fxFlex="45" fxFlexOffset="1">
          <div class="box" fxFlex="100">
            <select
              formControlName="typeDocument"
              (change)="onChangeSelect($event.target.value)"
              [ngClass]="{
                'is-danger-select':
                  form.controls['typeDocument'].invalid &&
                  (form.controls['typeDocument'].dirty || form.controls['typeDocument'].touched)
              }"
            >
              <option disabled value="null">Selecciona un tipo de documento</option>
              <option *ngFor="let item of typeDocuments$ | async | sorted: 'name'" [value]="item.id">
                {{ item.mask }} - {{ item.name }}
              </option>
            </select>
          </div>
          <div fxFlex="100" fxFlexAlign="start" *ngFor="let item of typeMessages">
            <span
              class="invalid"
              *ngIf="form.controls['typeDocument'].hasError(item.type) && form.controls['typeDocument'].touched"
              >{{ item.message }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- numero-identificacion -->
    <div class="container-row">
      <div
        fxLayout="row"
        fxLayout.xs="row wrap"
        class="space"
        [ngClass.xs]="'mt-1'"
        fxLayoutAlign.xs="space-evenly stretch"
        fxLayoutAlign="start center"
      >
        <label
          fxFlex.xs="100"
          fxFlexOffset.sm="22.5"
          fxFlex.sm="20"
          fxFlexOffset.md="7.5"
          fxFlex.md="35"
          fxFlexOffset.lg="5"
          fxFlex.lg="35"
          fxFlex.xl="33"
          [ngClass.xs]="'mb-1'"
          >Identificación</label
        >
        <div fxLayout="row wrap" fxFlex.xs="100" fxFlex="45" fxFlexOffset="1">
          <input
            fxFlex="100"
            type="text"
            formControlName="numberDocument"
            placeholder="Digita tu número de identificación"
            [pattern]="typeDocument?.regex"
            [ngClass]="{
              'is-danger-input':
                form.controls['numberDocument'].invalid &&
                (form.controls['numberDocument'].dirty || form.controls['numberDocument'].touched)
            }"
          />
          <div fxFlex="100" fxFlexAlign="start" *ngFor="let item of typeMessages">
            <span
              class="invalid"
              *ngIf="
                form.controls['numberDocument'].hasError(item.type) &&
                (form.controls['numberDocument'].dirty || form.controls['numberDocument'].touched)
              ">{{ item.message }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- re-captcha -->
    <div class="captcha" fxLayoutAlign="center center">
      <re-captcha formControlName="captcha"></re-captcha>
    </div>
    <!-- botones -->
    <div class="container-end">
      <div fxLayout="row" fxLayoutAlign.xs="space-between center" fxLayoutAlign="center center">
        <button mat-raised-button type="button" fxFlexAlign.xs="start" class="button button-back" (click)="onBack()">
          <img src="../../../../../assets/images/volver.png" />Volver
        </button>
        <button
          [disabled]="form.invalid"
          fxFlexAlign.xs="end"
          fxFlexOffset.sm="10"
          fxFlexOffset.md="10"
          fxFlexOffset.lg="8"
          fxFlexOffset.xl="6"
          class="button-continue"
          (click)="onSubmit()"
        >
          Continuar<img src="../../../../../assets/images/continuar.png" />
        </button>
      </div>
    </div>
  </form>
</div>
