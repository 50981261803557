import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../services/auth/authentication.service';

/**
 * Interceptor encargado de agregar el token a la solicitud
 */
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  /**
   * Servicio encargado de administrar la autenticación del usuario
   */
  private readonly authService: AuthenticationService;

  /**
   * Crea una nueva instancia de @see TokenInterceptorService
   * @param authService Servicio encargado de administrar la autenticación del usuario
   */
  public constructor(authService: AuthenticationService) {
    this.authService = authService;
  }

  /**
   * Identifies and handles a given HTTP request.
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns An observable of the event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api')) {
      if (this.authService.isAuthenticated) {
        const request = req.clone({
          headers: this.authService.getHeaders(),
        });

        return next.handle(request);
      }
    }

    return next.handle(req);
  }
}
