import { Component } from '@angular/core';
import { ConfigModalsAssignmentValidation } from '../../dialogs/custom-modal/config/assignment/config-modals-validation';
import { CustomModalComponent } from '../../dialogs/custom-modal/custom-modal.component';
import { MatDialog } from '@angular/material/dialog';

/**
 * Componente encargado de administrar el footer de la pagina
 */
@Component({
  selector: 'prv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /**
   * It is invoked only once when the directive is instantiated.
   */
   /**
   * Permite desplegar un modal
   */
   private readonly dialog: MatDialog;
  /**
   * Atributo encargado de obtener la configuración del contenido de la ventana modal
   */
  public configModalsAssignmentValidation: ConfigModalsAssignmentValidation;

  public constructor(dialog: MatDialog) {
    this.dialog = dialog;
    this.configModalsAssignmentValidation = new ConfigModalsAssignmentValidation();
  }

  public openDataModal() {
    this.dialog.open(CustomModalComponent, {
      data: this.configModalsAssignmentValidation.modalDataConsumer(),
      width: '720px',
      height: '400px',
      panelClass: 'dialog-container',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });
  }
}
