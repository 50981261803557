import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe encargado de transformar un valor booleano a un string (Activo | Inactivo)
 */
@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  /**
   * Transforma un valor booleano a string
   * @param value Valor a transformar
   * @returns Retorna Activo cuando el valor booleano es true, de lo contrario retorna Inactivo
   */
  public transform(value: boolean): string {
    return value ? 'Activo' : 'Inactivo';
  }
}
